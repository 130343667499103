import {PageTitle} from "../../../_metronic/layout/core";
import {useIntl} from "react-intl";
import  TableData from '../../modules/widgets/components/TableData'
import React from 'react'
import {LiveData} from '../../modules/widgets/components/LiveData'


const LiveDataPage = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]} >
                Live Monitoring
            </PageTitle>
            <div>
              <div className='g-5 gx-xxl-8'>
                      <LiveData/>
                </div>
            </div>
        </>

    )
}

const LiveDataWrapper = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
            <LiveDataPage/>
        </>
    )
}

export {LiveDataWrapper}
