import axios from 'axios'
import {AuthModel, UserModel} from './_models'
import {supabase} from "../../../supabaseClient";
import {ID} from '../../../../_metronic/helpers'
import {User} from '../../apps/user-management/users-list/core/_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export async function login(email: string, password: string) {
  console.log("login",email,password);
  const {data} = await supabase.auth.signInWithPassword({email, password});
  console.log(data);
  // @ts-ignore
 return { data:createAuthModel(data.session.access_token, data.session.refresh_token)};
}

const createAuthModel = (api_token: string , refreshToken?: string ): AuthModel => {
  return {
    api_token,
    refreshToken, // This property is optional, you can leave it out if you don't have a refreshToken.
  };
};

const createUserModel = (user: any): UserModel => {
    return {
        email: user.email,
        first_name: user.firstname,
        last_name: user.lastname,
      org_id: user.org_id,
      role: user.role,
      id: user.id,
    };
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export async function requestPassword(email: string) {
  console.log("requestPassword",email);
  const {data,error}=  await supabase.auth
    .resetPasswordForEmail(email)
  console.log(data,error);
  return {data,error}
}

export async function getUserByToken(token: string) {
    const {data: {user}} = await supabase.auth.getUser()

  let {data, error} = await supabase
    .from('users_list')
    .select()
    .eq('email', user?.email)
  let data1: User | undefined = data ? data[0] : undefined;

    return {data: createUserModel(data1)};
}


