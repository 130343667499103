import {supabase} from '../supabaseClient'
import _ from 'lodash'
import moment from 'moment-timezone'

export async function getHtData(reportDate:string,dev_id:string) {
  const { data:rpcd, error:err } = await supabase
    .rpc('get_ht_data_stats', { start_date: reportDate,dev_id })
  return rpcd;
}

export async function getLtData(reportDate:string,dev_id:string) {
  const { data:rpcd, error:err } = await supabase
    .rpc('get_lt_data_stats', { start_date: reportDate,dev_id })
  return rpcd;
}

export async function getCtData(reportDate:string,dev_id:string) {

  const { data:rpcd, error:err } = await supabase
    .rpc('get_ct_data_stats', { start_date: reportDate,dev_id })
  return rpcd;

}

export function updateRawObjectKeys(data:any,type:string){
  let key_map:any;
  switch(type){
    case "lt":
      key_map = lt_key_map
      break;
    case "ht":
      key_map = ht_key_map
      break;
    case "ct":
      key_map = ct_key_map
      break;

  }

  const key_map_values = Object.keys(key_map);

  // Filter out keys not present in the respective map
  // @ts-ignore
  data = _.pick(data, key_map_values);

  let new_data = _.mapKeys(data, function(value, key) {
    return key_map[key] || key;
  });
  new_data.time = moment.tz(new_data.device_time,"Asia/Kolkata").format("DD-MM-YYYY HH:mm:ss z");

  return new_data
}

const  lt_key_map = {
  lt_frq: "Frequency",
  lt_current: "Current",
  lt_out_volt: "Output Voltage",
  lt_in_pwr: "Input Power",
  lt_accl: "Acceleration",
  lt_deccl: "Deceleration",
  lt_out_speed: "Output Speed",
  lt_dc_volt: "DC Bus Voltage",
  lt_heat_sink_temp: "Heat Sink Temperature",
  lt_in_temp: "Inside Temperature",
};

let ht_key_map = {
  ht_frq: "Frequency",
  ht_current: "Current",
  ht_out_volt: "Output Voltage",
  ht_in_pwr: "Input Power",
  ht_accl: "Acceleration",
  ht_deccl: "Deceleration",
  ht_out_speed: "Output Speed",
  ht_dc_volt: "DC Bus Voltage",
  ht_heat_sink_temp: "Heat Sink Temperature",
  ht_in_temp: "Inside Temperature",
  ht_load_cell: "Load Cell"
};

let ct_key_map = {
  ct_frq: "Frequency",
  ct_current: "Current",
  ct_out_volt: "Output Voltage",
  ct_in_pwr: "Input Power",
  ct_accl: "Acceleration",
  ct_deccl: "Deceleration",
  ct_out_speed: "Output Speed",
  ct_dc_volt: "DC Bus Voltage",
  ct_heat_sink_temp: "Heat Sink Temperature",
};
