import React, {FC, useEffect, useState} from 'react'
import Pagination from './Pagination'
// @ts-ignore
import * as XLSX from 'xlsx/xlsx.mjs'
import {Card, Table} from 'react-bootstrap'
import moment from 'moment'
import {paginate, removeIdAndAddSrNo} from '../../../util/paginate'
import {supabase} from '../../../supabaseClient'
import {KTIcon} from '../../../../_metronic/helpers'
import ExcelJS from 'exceljs'
import {DailySummaryWidget} from '../../../../_metronic/partials/widgets/_new/cards/DailySummaryWidget'
import {ChartsWidgetCrainMovement} from '../../../../_metronic/partials/widgets/charts/ChartsWidgetCrainMovement'
import Switch from 'react-bootstrap/Switch'
import {getActionData, getDeviceData} from '../../apps/device-data/_requests'
import {DeviceData} from '../../apps/device-data/_models'
import {useAuth} from '../../auth'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import _ from 'lodash'

const ReportData: FC = () => {
  const [refreshEnabled, setRefreshEnabled] = useState(true)
  const [is7Days, setIs7Days] = useState(true)
  const [ltChartData, setLtChartData] = useState<DeviceData[]>([])
  const [ctChartData, setCtChartData] = useState<DeviceData[]>([])
  const [htChartData, setHtChartData] = useState<DeviceData[]>([])
  const [chartData, setChartData] = useState<DeviceData[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [deviceList, setDeviceList] = useState<any>([])
  const [alarmChartData, setAlarmChartData] = useState<any[]>([])
const [motionValue, setMotionValue] = useState<string>('HT')
  const [selectedDevice, setSelectedDevice] = useState<any>(0)
  const {currentUser, logout} = useAuth()

  const toggleRefreshEnabled = () => {
    setRefreshEnabled(!refreshEnabled)
  }

  const handleChange = () => {
    setIs7Days(!is7Days)
  }

  useEffect(() => {
    const fetchDevices = async () => {
      let result
      if (currentUser?.role === 'customer-admin' || currentUser?.role === 'customer-user') {
        result = await supabase
          .from('device')
          .select('id,device_name')
          .eq('org_id', currentUser?.org_id)
      } else {
        result = await supabase.from('device').select('id,device_name')
      }

      if (result.error) console.error('Error fetching devices:', result.error)
      else {
        // @ts-ignore
        setDeviceList(result.data)
      }
    }
    fetchDevices()
    setSelectedDevice(window.localStorage.getItem('selectedDevice'))
    // console.log('selectedDevice=========',window.localStorage.getItem('selectedDevice'))
    fetchData(window.localStorage.getItem('selectedDevice') || '0',motionValue)
    fetchActionData(window.localStorage.getItem('selectedDevice') || '0', 'HT')
  }, [])
  const fetchData = async (selectedDevice: string,motion:string,p_is7Days:boolean=is7Days) => {
    setIsLoading(true)

    switch (motion) {
      case 'HT':
        const responseh = await getDeviceData(
          `ht_data`,
          moment()
            .subtract(p_is7Days ? 7 : 30, 'days')
            .format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
          selectedDevice
        )
        setChartData(responseh)
        break
      case 'CT':
        const responsec = await getDeviceData(
          `ct_data`,
          moment()
            .subtract(p_is7Days ? 7 : 30, 'days')
            .format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
          selectedDevice
        )
        setChartData(responsec)
        break
      case 'LT':
        const responsel = await getDeviceData(
          `lt_data`,
          moment()
            .subtract(p_is7Days ? 7 : 30, 'days')
            .format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
          selectedDevice
        )
        setChartData(responsel)
        break

    }



    setIsLoading(false)
    // setDataUpdated(dataUpdated+1);
  }


  function getChartTile(motion:string) {
    switch (motion) {
      case 'HT':
        return 'Hoist'
      case 'CT':
        return 'Cross Travel'
      case 'LT':
        return 'Long Travel'
    }
  }
  const fetchActionData = async (selectedDevice: string,motion:string,is7Daysval:boolean=is7Days) => {
    setIsLoading(true)

    const responseh = await getActionData(
      moment()
        .subtract(is7Daysval ? 7 : 30, 'days')
        .format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD'),
      selectedDevice,
      motion
    )
    setAlarmChartData(responseh)
    setIsLoading(false)
    // setDataUpdated(dataUpdated+1);
  }

  // if (isLoading) {
  //   return <div>Loading...</div> // replace with your preferred loading indicator
  // }
  return (
    <>
      <div className='row gy-5 g-xl-4'>
        {/* begin::Col */}

        <div className='col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
          <div className='d-flex my-2'>
            <select
              name='status'
              data-control='select2'
              data-hide-search='true'
              className='form-select form-select-solid form-select-lg'
              value={selectedDevice}
              onChange={(e) => {
                setSelectedDevice(e.target.value)
                window.localStorage.setItem('selectedDevice', e.target.value)
                fetchActionData(e.target.value,motionValue)
                fetchData(e.target.value,motionValue)
              }}
            >
              <option key='0' value='Select Crane'>
                Select Crane
              </option>
              {deviceList.map((device: any) => (
                <option key={device.id} value={device.id}>
                  {device.device_name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className='col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
          <div className='d-flex my-2'>
            <BootstrapSwitchButton
              onlabel='Last 7 days'
              offlabel='Last 30 days'
              checked={is7Days}
              onChange={(checked: boolean) => {
                setIs7Days(checked)
                fetchActionData(selectedDevice,motionValue,checked)
                fetchData(selectedDevice,motionValue,checked)
              }}
              style='w-100 mx-2'
            />
          </div>
        </div>
        <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
          <div className='d-flex my-2'>
            <ToggleButtonGroup type="radio" name="options" value={motionValue} onChange={
              (e) => {
                setMotionValue(e)
                fetchActionData(selectedDevice,e)
                fetchData(selectedDevice,e)

              }
            }>
              <ToggleButton id="tbg-check-1"    variant="secondary"
                            value={'HT'}>Hoist</ToggleButton>
              <ToggleButton id="tbg-check-2"  variant="secondary" value={'CT'}>Cross Travel</ToggleButton>
              <ToggleButton id="tbg-check-3"  variant="secondary" value={'LT'}>Long Travel</ToggleButton>
            </ToggleButtonGroup>

          </div>
        </div>



      </div>
      <div className='row g-5 g-xl-10'>
        {/* begin::Col */}
        <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
          <div className='card-body'></div>
        </div>
      </div>

      {/* begin::Row */}
      <div className='row g-5 g-xl-10'>
        {/* begin::Col */}
        <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
          <div className='card-body'>
            <ChartsWidgetCrainMovement
              className='h-md-50 mb-5'
              title={`${getChartTile(motionValue)} Cycle Summary`}
              description=''
              type={_.lowerCase(motionValue)}
              device_id={3}
              chartType={1}
              refreshEnabled={refreshEnabled}
              chartData={chartData}
            />
          </div>
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row g-5 g-xl-10'>
        {/* begin::Col */}
        <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
          <div className='card-body'>
            <ChartsWidgetCrainMovement
              className='h-md-50 mb-5'
              title={`${getChartTile(motionValue)} Time Summary`}
              description=''
              type={_.lowerCase(motionValue)}
              device_id={3}
              chartType={2}
              refreshEnabled={refreshEnabled}
              chartData={chartData}
            />
          </div>
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row g-5 g-xl-10 '>
        {/* begin::Col */}
        <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
          <div className='card-body'>
            <ChartsWidgetCrainMovement
              className='h-md-50 mb-5'
              title={`${getChartTile(motionValue)} Brake Summary`}
              description=''
              type={_.lowerCase(motionValue)}
              device_id={3}
              chartType={3}
              refreshEnabled={refreshEnabled}
              chartData={chartData}
            />
          </div>
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row g-5 g-xl-10 '>
        {/* begin::Col */}
        <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
          <div className='card-body'>
            <ChartsWidgetCrainMovement
              className='h-md-50 mb-5'
              title={`${getChartTile(motionValue)} Trip Summary(Alarms)`}
              description=''
              type={_.lowerCase(motionValue)}
              device_id={3}
              chartType={4}
              refreshEnabled={refreshEnabled}
              chartData={alarmChartData}
            />
          </div>
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}
    </>
  )
}

//   function updateData() {
//     fetch('/api/device_data/1')
//     .then((res) => res.json())
//     .then((ltData) => {
//         ltData(ltData.data)
//     })
//   }

export default ReportData
