
import { DeviceData } from './_models'
import {supabase} from '../../../supabaseClient'
import _ from 'lodash'
import moment from 'moment'

const getDeviceData = async (table_name:string, startDate:string, endDate:string,dev_id:string): Promise<DeviceData[]> => {
  const { data: rpcd, error: err } = await supabase
    .rpc('get_device_data', { tbl_name: table_name, start_date: startDate, end_date: endDate,dev_id})

  console.log(rpcd)
  if (err) {
    console.error('Error fetching device data:', err);
    throw err;
  }
  let newData = _.map(rpcd, function(item) {
    let newItem = _.cloneDeep(item); // Clone the item to avoid modifying the original data
    newItem.day = moment(newItem.day).format('DD-MMM-YY');
    return newItem;
  });
  console.log(newData);
  return newData;
};
  const getActionData = async ( startDate:string, endDate:string,dev_id:string,motion:string): Promise<DeviceData[]> => {
    const { data: rpcd, error: err } = await supabase
      .rpc('get_action_data', {  p_start_date: startDate, p_end_date: endDate,p_device_id:dev_id,p_motion: motion})

    console.log(rpcd)
    if (err) {
      console.error('Error fetching device data:', err);
      throw err;
    }
    let newData = _.map(rpcd, function(item) {
      let newItem = _.cloneDeep(item); // Clone the item to avoid modifying the original data
      newItem.day = moment(newItem.day).format('DD-MMM-YY');
      return newItem;
    });

    console.log(newData);
    return newData;
  };

// const getAlarmData = async ( startDate:string, endDate:string,dev_id:string,motion:string): Promise<DeviceData[]> => {
//   const { data: rpcd, error: err } = await supabase.select('*').from('action_data').eq('device_id',dev_id).gte('created_at',startDate).lte('created_at',endDate).eq('motion',motion)
//
//   console.log(rpcd)
//   if (err) {
//     console.error('Error fetching device data:', err);
//     throw err;
//   }
//   let newData = _.map(rpcd, function(item) {
//     let newItem = _.cloneDeep(item); // Clone the item to avoid modifying the original data
//     newItem.day = moment(newItem.day).format('MM-DD');
//     return newItem;
//   });
//
//   console.log(newData);
//   return newData;
// };



export {getDeviceData,getActionData}
