import {PageTitle} from "../../../_metronic/layout/core";
import {useIntl} from "react-intl";
import React from 'react'
import DeviceHealth from '../../modules/widgets/components/DeviceHealth'
import DeviceHealthReset from '../../modules/widgets/components/DeviceHealthReset'


const DeviceHealthResetPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]} >
        Device Health Data Reset
      </PageTitle>
      <div>
        <div className='g-5 gx-xxl-8'>
          <DeviceHealthReset/>
        </div>
      </div>
    </>

  )
}

const DeviceHealthParamResetWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DeviceHealthResetPage/>
    </>
  )
}

export {DeviceHealthParamResetWrapper}
