import {PageTitle} from "../../../_metronic/layout/core";
import {useIntl} from "react-intl";
import  TableData from '../../modules/widgets/components/TableData'
import React from 'react'
import ReportData from '../../modules/widgets/components/ReportData'


const ReportPage = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]} >
                Report
            </PageTitle>
            <div>
              <div className='g-5 gx-xxl-8'>
                      <ReportData/>
                </div>
            </div>
        </>

    )
}

const ReportWrapper = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
            <ReportPage/>
        </>
    )
}

export {ReportWrapper}
