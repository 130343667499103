import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
    ListsWidget3,
    ListsWidget4,
    ListsWidget5,
    ListsWidget9,
    MixedWidget6,
    MixedWidget7,
    MixedWidget8,
    StatisticsWidget5,
    TablesWidget11,
    TablesWidget9,
} from '../../../_metronic/partials/widgets'
import {Device1Widget} from '../../../_metronic/partials/widgets/mixed/Device1'
import {Device2Widget} from '../../../_metronic/partials/widgets/mixed/Device2'
import {Device3Widget} from '../../../_metronic/partials/widgets/mixed/Device3'

const DashboardPage = () => {
  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]} description=''>
        Live Monitoring
      </PageTitle>


      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        {/* begin::Col */}
        <div className='col-xl-4'>

          <Device1Widget
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='150px'
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-4'>
          <Device2Widget
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='150px'
          />
        </div>
        {/* end::Col */}
        {/* begin::Col */}
        <div className='col-xl-4'>
          <Device3Widget
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='150px'
          />
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}


    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>Live Monitoring</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
