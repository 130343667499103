/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'

import _ from 'lodash'
import moment from 'moment'
import {Card} from 'react-bootstrap'
import {supabase} from '../../../supabaseClient'
import {getCtData, getHtData, getLtData, updateRawObjectKeys} from '../../../util/LiveData'
import {Device} from '../../apps/device-management/device-list/core/_models'
import {useAuth} from '../../auth'
import {ChartsWidgetCrainMovement} from '../../../../_metronic/partials/widgets/charts/ChartsWidgetCrainMovement'
import {DeviceData} from '../../apps/device-data/_models'
import {getDeviceData} from '../../apps/device-data/_requests'
import {DailySummaryWidget} from '../../../../_metronic/partials/widgets/_new/cards/DailySummaryWidget'

export const LiveData: FC = () => {
  const [message, setMessage] = useState('')
  const [rawHtData, setRawHtData] = useState({})
  const [rawCtData, setRawCtData] = useState({})
  const [rawLtData, setRawLtData] = useState({})
  const [htAlarmStatus, setHtAlarmStatus] = useState('')
  const [ctAlarmStatus, setCtAlarmStatus] = useState('')
  const [ltAlarmStatus, setLtAlarmStatus] = useState('')

  const [showPulseRingHt, setShowPulseRingHt] = useState(false)
  const [showPulseRingLt, setShowPulseRingLt] = useState(false)

  const [htChartData, setHtChartData] = useState<DeviceData[]>([])
  const [ltChartData, setLtChartData] = useState<DeviceData[]>([])
  const [ctChartData, setCtChartData] = useState<DeviceData[]>([])

  const [showPulseRingCt, setShowPulseRingCt] = useState(false)
  const [deviceList, setDeviceList] = useState<any>([])
  const [selectedDevice, setSelectedDevice] = useState<any>(0)
  const {currentUser, logout} = useAuth()
  const [spanHtValues, setSpanHtValues] = useState({
    htUpCount: 0,
    htDownCount: 0,
    htSpeedCount: 0,
    htUpTime: 0,
    htDownTime: 0,
    htBrakeCount: 0,
    htRevDsCount: 0,
    htForwardDsCount: 0,
  })

  const [spanCtValues, setSpanCtValues] = useState({
    ctRightCount: 0,
    ctLeftCount: 0,
    ctSpeedCount: 0,
    ctRightTime: 0,
    ctLeftTime: 0,
    ctBrakeCount: 0,
    ctRevDsCount: 0,
    ctForwardDsCount: 0,
  })
  const [spanLtValues, setSpanLtValues] = useState({
    ltFwdCount: 0,
    ltRevCount: 0,
    ltSpeedCount: 0,
    ltForwardTime: 0,
    ltReverseTime: 0,
    ltBrakeCount: 0,
    ltRevDsCount: 0,
    ltForwardDsCount: 0,
  })

  useEffect(() => {
    const fetchDevices = async () => {
      let result
      if (currentUser?.role === 'customer-admin' || currentUser?.role === 'customer-user') {
        result = await supabase
          .from('device')
          .select('id,device_name')
          .eq('org_id', currentUser?.org_id)
      } else {
        result = await supabase.from('device').select('id,device_name')
      }

      if (result.error) console.error('Error fetching devices:', result.error)
      else {
        // @ts-ignore
        setDeviceList(result.data)
      }
    }

    fetchDevices()
    setData(window.localStorage.getItem('selectedDevice') || '0')
    setSelectedDevice(window.localStorage.getItem('selectedDevice'))
  }, [])

  function setData(selectedDevice = '') {
    getHtData(moment().format('YYYY-MM-DD'), selectedDevice).then((data) => {
      if (data) {
        setSpanHtValues({
          htUpCount: _.get(data[0], 'forward_count', 0),
          htDownCount: _.get(data[0], 'backward_count', 0),
          htSpeedCount: _.get(data[0], 'rev_ds_count', 0) + _.get(data[0], 'forward_ds_count', 0),
          htUpTime: _.get(data[0], 'total_fwd_cycle_time', 0),
          htDownTime: _.get(data[0], 'total_rev_cycle_time', 0),
          htBrakeCount: _.get(data[0], 'break_signal_count', 0),
          htRevDsCount: _.get(data[0], 'rev_ds_count', 0),
          htForwardDsCount: _.get(data[0], 'forward_ds_count', 0),
        })
      }
    })

    getCtData(moment().format('YYYY-MM-DD'), selectedDevice).then((data) => {
      if (data) {
        setSpanCtValues({
          ctRightCount: _.get(data[0], 'forward_count', 0),
          ctLeftCount: _.get(data[0], 'backward_count', 0),
          ctSpeedCount: _.get(data[0], 'rev_ds_count', 0) + _.get(data[0], 'forward_ds_count', 0),
          ctRightTime: _.get(data[0], 'total_fwd_cycle_time', 0),
          ctLeftTime: _.get(data[0], 'total_rev_cycle_time', 0),
          ctBrakeCount: _.get(data[0], 'break_signal_count', 0),
          ctRevDsCount: _.get(data[0], 'rev_ds_count', 0),
          ctForwardDsCount: _.get(data[0], 'forward_ds_count', 0),
        })
      }
    })

    getLtData(moment().format('YYYY-MM-DD'), selectedDevice).then((data) => {
      if (data) {
        setSpanLtValues({
          ltFwdCount: _.get(data[0], 'forward_count', 0),
          ltRevCount: _.get(data[0], 'backward_count', 0),
          ltSpeedCount: _.get(data[0], 'rev_ds_count', 0) + _.get(data[0], 'forward_ds_count', 0),
          ltForwardTime: _.get(data[0], 'total_fwd_cycle_time', 0),
          ltReverseTime: _.get(data[0], 'total_rev_cycle_time', 0),
          ltBrakeCount: _.get(data[0], 'break_signal_count', 0),
          ltRevDsCount: _.get(data[0], 'rev_ds_count', 0),
          ltForwardDsCount: _.get(data[0], 'forward_ds_count', 0),
        })
      }
    })
    setLiveDataSubscriber(selectedDevice)
  }

  function setLiveDataSubscriber(selectedDevice: string) {
    setHtLiveData(selectedDevice)
    setLtLiveData(selectedDevice)
    setCtLiveData(selectedDevice)
    setActionLiveData(selectedDevice)
  }
  function setHtLiveData(selectedDevice: string) {
    const channel = supabase
      .channel('deviceid3-ht')
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'ht_data',
          filter: `device_id=eq.${selectedDevice}`,
        },
        (payload) => {
          setRawHtData(updateRawObjectKeys(_.get(payload, 'new'), 'ht'))
          const deviceReading = _.get(payload, 'new')

          setSpanHtValues((prevState) => ({
            ...prevState,
            htUpCount:
              _.get(deviceReading, 'forward') &&
              (_.isUndefined(_.get(deviceReading, 'double_speed')) ||
                _.get(deviceReading, 'double_speed') === false)
                ? prevState.htUpCount + 1
                : prevState.htUpCount,
            htDownCount:
              _.get(deviceReading, 'backward') &&
              (_.isUndefined(_.get(deviceReading, 'double_speed')) ||
                _.get(deviceReading, 'double_speed') === false)
                ? prevState.htDownCount + 1
                : prevState.htDownCount,
            htSpeedCount:
              (_.get(deviceReading, 'rev_double_speed') ) ||
              (_.get(deviceReading, 'fwd_double_speed') )
                ? prevState.htSpeedCount + 1
                : prevState.htSpeedCount,
            htUpTime:
              _.get(deviceReading, 'fwd_cycle_time', null) !== null
                ? prevState.htUpTime + _.get(deviceReading, 'fwd_cycle_time')
                : prevState.htUpTime,
            htDownTime:
              _.get(deviceReading, 'rev_cycle_time', null) !== null
                ? prevState.htDownTime + _.get(deviceReading, 'rev_cycle_time')
                : prevState.htDownTime,
            htBrakeCount: _.get(deviceReading, 'break_signal')
              ? prevState.htBrakeCount + 1
              : prevState.htBrakeCount,
          }))
          setShowPulseRingHt(true)
          if (deviceReading.device_id !== 0) {
            fetchData(deviceReading.device_id)
          }
        }
      )
      .subscribe()
  }

  function setLtLiveData(selectedDevice: string) {
    const channel = supabase
      .channel('deviceid3-lt')
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'lt_data',
          filter: `device_id=eq.${selectedDevice}`,
        },
        (payload) => {
          const deviceReading = _.get(payload, 'new')
          setRawLtData(updateRawObjectKeys(_.get(payload, 'new'), 'lt'))

          setSpanLtValues((prevState) => ({
            ...prevState,
            ltFwdCount:
              _.get(deviceReading, 'forward') &&
              (_.isUndefined(_.get(deviceReading, 'double_speed')) ||
                _.get(deviceReading, 'double_speed') === false)
                ? prevState.ltFwdCount + 1
                : prevState.ltFwdCount,
            ltRevCount:
              _.get(deviceReading, 'backward') &&
              (_.isUndefined(_.get(deviceReading, 'double_speed')) ||
                _.get(deviceReading, 'double_speed') === false)
                ? prevState.ltRevCount + 1
                : prevState.ltRevCount,
            ltSpeedCount:
              (_.get(deviceReading, 'fwd_double_speed') ) ||
              (_.get(deviceReading, 'rev_double_speed') )
                ? prevState.ltSpeedCount + 1
                : prevState.ltSpeedCount,
            ltForwardTime:
              _.get(deviceReading, 'fwd_cycle_time', null) !== null
                ? prevState.ltForwardTime + _.get(deviceReading, 'fwd_cycle_time')
                : prevState.ltForwardTime,
            ltReverseTime:
              _.get(deviceReading, 'rev_cycle_time', null) !== null
                ? prevState.ltReverseTime + _.get(deviceReading, 'rev_cycle_time')
                : prevState.ltReverseTime,
            ltBrakeCount: _.get(deviceReading, 'break_signal')
              ? prevState.ltBrakeCount + 1
              : prevState.ltBrakeCount,
          }))
          setShowPulseRingLt(true)
          if (deviceReading.device_id !== 0) {
            fetchData(deviceReading.device_id)
          }
        }
      )
      .subscribe()
  }
  function setCtLiveData(selectedDevice: string) {
    const channel = supabase
      .channel('deviceid3-ct')
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'ct_data',
          filter: `device_id=eq.${selectedDevice}`,
        },
        (payload) => {
          const deviceReading = _.get(payload, 'new')
          setRawCtData(updateRawObjectKeys(_.get(payload, 'new'), 'ct'))
          setSpanCtValues((prevState) => ({
            ...prevState,
            ctRightCount:
              _.get(deviceReading, 'forward') &&
              (_.isUndefined(_.get(deviceReading, 'double_speed')) ||
                _.get(deviceReading, 'double_speed') === false)
                ? prevState.ctRightCount + 1
                : prevState.ctRightCount,
            ctLeftCount:
              _.get(deviceReading, 'backward') &&
              (_.isUndefined(_.get(deviceReading, 'double_speed')) ||
                _.get(deviceReading, 'double_speed') === false)
                ? prevState.ctLeftCount + 1
                : prevState.ctLeftCount,
            ctSpeedCount:
              (_.get(deviceReading, 'fwd_double_speed') ) ||
              (_.get(deviceReading, 'rev_double_speed') )
                ? prevState.ctSpeedCount + 1
                : prevState.ctSpeedCount,
            ctRightTime:
              _.get(deviceReading, 'fwd_cycle_time', null) !== null
                ? prevState.ctRightTime + _.get(deviceReading, 'fwd_cycle_time')
                : prevState.ctRightTime,
            ctLeftTime:
              _.get(deviceReading, 'rev_cycle_time', null) !== null
                ? prevState.ctLeftTime + _.get(deviceReading, 'rev_cycle_time')
                : prevState.ctLeftTime,
            ctBrakeCount: _.get(deviceReading, 'break_signal')
              ? prevState.ctBrakeCount + 1
              : prevState.ctBrakeCount,
          }))
          setShowPulseRingLt(true)
          if (deviceReading.device_id !== 0) {
            fetchData(deviceReading.device_id)
          }
        }
      )
      .subscribe()
  }
  function setActionLiveData(selectedDevice: string) {
    const channel = supabase
      .channel('deviceid3-at')
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'action_data',
          filter: `device_id=eq.${selectedDevice}`,
        },
        (payload) => {
          const deviceReading = _.get(payload, 'new')
          switch (deviceReading.motion) {
            case 'HT':
              setHtAlarmStatus(deviceReading.alarm_code)
              break
            case 'CT':
              setCtAlarmStatus(deviceReading.alarm_code)
              break
            case 'LT':
              setLtAlarmStatus(deviceReading.alarm_code)
              break
            default:
              break
          }
        }
      )
      .subscribe()
  }
  const [refreshEnabled, setRefreshEnabled] = useState(false)

  const toggleRefreshEnabled = () => {
    setRefreshEnabled(!refreshEnabled)
    console.log('refreshing+++++', refreshEnabled)
  }

  async function fetchData(device_id: string) {
    // setIsLoading(true)
    const responseh = await getDeviceData(
      `ht_data`,
      moment().subtract(2, 'days').format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD'),
      device_id
    )
    setHtChartData(responseh)
    const responsec = await getDeviceData(
      `ct_data`,
      moment().subtract(2, 'days').format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD'),
      device_id
    )
    setCtChartData(responsec)
    const responsel = await getDeviceData(
      `lt_data`,
      moment().subtract(2, 'days').format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD'),
      device_id
    )
    setLtChartData(responsel)
    // setIsLoading(false)
    // setDataUpdated(dataUpdated+1);
  }

  return (
    <>
      <div className='row gy-5 g-xl-8'>
        {/* begin::Col */}
        <div className='col-xl-12'>
          <div className='d-flex my-2'>
            <select
              name='status'
              data-control='select2'
              data-hide-search='true'
              className='form-select form-select-white form-select-sm w-125px'
              value={window.localStorage.getItem('selectedDevice') || 0}
              onChange={(e) => {
                setSelectedDevice(e.target.value)
                setData(e.target.value)
                setLiveDataSubscriber(e.target.value)
                fetchData(e.target.value)
                // @ts-ignore
                window.localStorage.setItem('selectedDevice', e.target.value)
                // setRefreshEnabled(true)
              }}
            >
              <option key='0' value='Select Crane'>
                Select Crane
              </option>
              {deviceList.map((device: any) => (
                <option key={device.id} value={device.id}>
                  {device.device_name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className='row g-5'>
        <div className='col-lg-6'>
          <div className='card card-stretch-80 card-bordered mb-5'>
            <div className='card-body d-flex justify-content-between align-items-start flex-column'>
              <table className='table table-bordered text-center'>
                <tbody>
                  <tr>
                    <td>
                      <div className='d-flex flex-column'>
                        <div className='m-0'>
                          {showPulseRingHt && <span className='pulse-ring border-5 '></span>}
                          <span className='fw-semibold fs-6 text-black-400'>HT Up Count</span>
                        </div>
                        <span className='fw-semibold fs-3x text-gray-800 '>
                          {spanHtValues.htUpCount}
                        </span>
                      </div>
                    </td>
                    <td>
                      {' '}
                      <div className='d-flex flex-column'>
                        <div className='m-0'>
                          {showPulseRingHt && <span className='pulse-ring border-5 '></span>}
                          <span className='fw-semibold fs-6 text-black-400'>HT Down Count </span>
                        </div>
                        <span className='fw-semibold fs-3x text-gray-800 '>
                          {spanHtValues.htDownCount}
                        </span>
                      </div>
                    </td>
                    <td>
                      {' '}
                      <div className='d-flex flex-column'>
                        <div className='m-0'>
                          {showPulseRingHt && <span className='pulse-ring border-5 '></span>}
                          <span className='fw-semibold fs-6 text-black-400'>HT Speed Count</span>
                        </div>
                        <span className='fw-semibold fs-3x text-gray-800 '>
                          {spanHtValues.htSpeedCount}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className='col-lg-4'>
          <div className='card card-stretch-80 card-bordered mb-5'>
            <div className='card-body d-flex justify-content-between align-items-start flex-column'>
              <table className=' table table-bordered text-center'>
                <tbody>
                  <tr>
                    <td>
                      <div className='d-flex flex-column'>
                        <div className='m-0'>
                          {showPulseRingHt && <span className='pulse-ring border-5 '></span>}
                          <span className='fw-semibold fs-6 text-black-400'>HT Up Time</span>
                        </div>
                        <span className='fw-semibold fs-3x text-gray-800 '>
                          {Math.floor(spanHtValues.htUpTime / 60000)}
                        </span>
                      </div>
                    </td>
                    <td>
                      {' '}
                      <div className='d-flex flex-column'>
                        <div className='m-0'>
                          {showPulseRingHt && <span className='pulse-ring border-5 '></span>}
                          <span className='fw-semibold fs-6 text-black-400'>HT Down Time </span>
                        </div>
                        <span className='fw-semibold fs-3x text-gray-800 '>
                          {Math.floor(spanHtValues.htDownTime / 60000)}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className='col-lg-2'>
          <div className='card card-stretch-80 card-bordered mb-5'>
            <div className='card-body d-flex justify-content-between align-items-start flex-column'>
              <table className='table table-bordered text-center'>
                <tbody>
                  <tr>
                    <td>
                      <div className='d-flex flex-column'>
                        <div className='m-0'>
                          {showPulseRingHt && <span className='pulse-ring border-5 '></span>}
                          <span className='fw-semibold fs-6 text-black-400'>HT Brake Count </span>
                        </div>
                        <span className='fw-semibold fs-3x text-gray-800 '>
                          {spanHtValues.htUpCount+spanHtValues.htDownCount}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className='row g-5'>
        <div className='col-lg-6'>
          <div className='card card-stretch-80 card-bordered mb-5'>
            <div className='card-body d-flex justify-content-between align-items-start flex-column'>
              <table className='table table-bordered text-center'>
                <tbody>
                  <tr>
                    <td>

                      <div className='d-flex flex-column'>
                        <div className='m-0'>
                          {showPulseRingCt && <span className='pulse-ring border-5 '></span>}
                          <span className='fw-semibold fs-6 text-black-400'>CT Right Count </span>
                        </div>
                        <span className='fw-semibold fs-3x text-gray-800 '>
                          {spanCtValues.ctRightCount}
                        </span>
                      </div>
                    </td>
                    <td>

                      <div className='d-flex flex-column'>
                        <div className='m-0'>
                          {showPulseRingCt && <span className='pulse-ring border-5 '></span>}
                          <span className='fw-semibold fs-6 text-black-400'>CT Left Count </span>
                        </div>
                        <span className='fw-semibold fs-3x text-gray-800 '>
                          {spanCtValues.ctLeftCount}
                        </span>
                      </div>
                    </td>
                    <td>

                      <div className='d-flex flex-column'>
                        <div className='m-0'>
                          {showPulseRingCt && <span className='pulse-ring border-5 '></span>}
                          <span className='fw-semibold fs-6 text-black-400'>CT Speed Count</span>
                        </div>
                        <span className='fw-semibold fs-3x text-gray-800 '>
                          {spanCtValues.ctSpeedCount}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className='col-lg-4'>
          <div className='card card-stretch-80 card-bordered mb-5'>
            <div className='card-body d-flex justify-content-between align-items-start flex-column'>
              <table className='table table-bordered text-center'>
                <tbody>
                  <tr>
                    <td>
                      <div className='d-flex flex-column'>
                        <div className='m-0'>
                          {showPulseRingCt && <span className='pulse-ring border-5 '></span>}
                          <span className='fw-semibold fs-6 text-black-400'>CT Right Time</span>
                        </div>
                        <span className='fw-semibold fs-3x text-gray-800 '>
                          {Math.floor(spanCtValues.ctRightTime / 60000)}
                        </span>
                      </div>
                    </td>
                    <td>
                      {' '}
                      <div className='d-flex flex-column'>
                        <div className='m-0'>
                          {showPulseRingCt && <span className='pulse-ring border-5 '></span>}
                          <span className='fw-semibold fs-6 text-black-400'>CT Left Time </span>
                        </div>
                        <span className='fw-semibold fs-3x text-gray-800 '>
                          {Math.floor(spanCtValues.ctLeftTime / 60000)}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='col-lg-2'>
          <div className='card card-stretch-80 card-bordered mb-5'>
            <div className='card-body d-flex justify-content-between align-items-start flex-column'>
              <table className='table table-bordered text-center'>
                <tbody>
                  <tr>
                    <td>
                      <div className='d-flex flex-column'>
                        <div className='m-0'>
                          {showPulseRingCt && <span className='pulse-ring border-5 '></span>}
                          <span className='fw-semibold fs-6 text-black-400'>CT Brake Count </span>
                        </div>
                        <span className='fw-semibold fs-3x text-gray-800 '>
                          {spanCtValues.ctRightCount+spanCtValues.ctLeftCount}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className='row g-5'>
        <div className='col-lg-6'>
          <div className='card card-stretch-80 card-bordered mb-5'>
            <div className='card-body d-flex justify-content-between align-items-start flex-column'>
              <table className='table table-bordered text-center'>
                <tbody>
                  <tr>
                    <td>
                      <div className='d-flex flex-column'>
                        <div className='m-0'>
                          {showPulseRingLt && <span className='pulse-ring border-5 '></span>}
                          <span className='fw-semibold fs-6 text-black-400'>LT FWD Count </span>
                        </div>
                        <span className='fw-semibold fs-3x text-gray-800 '>
                          {spanLtValues.ltFwdCount}
                        </span>
                      </div>
                    </td>
                    <td>
                      {' '}
                      <div className='d-flex flex-column'>
                        <div className='m-0'>
                          {showPulseRingLt && <span className='pulse-ring border-5 '></span>}
                          <span className='fw-semibold fs-6 text-black-400'>LT REV Count </span>
                        </div>
                        <span className='fw-semibold fs-3x text-gray-800 '>
                          {spanLtValues.ltRevCount}
                        </span>
                      </div>
                    </td>
                    <td>
                      {' '}
                      <div className='d-flex flex-column'>
                        <div className='m-0'>
                          {showPulseRingLt && <span className='pulse-ring border-5 '></span>}
                          <span className='fw-semibold fs-6 text-black-400'>LT Speed Count</span>
                        </div>
                        <span className='fw-semibold fs-3x text-gray-800 '>
                          {spanLtValues.ltSpeedCount}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className='col-lg-4'>
          <div className='card card-stretch-80 card-bordered mb-5'>
            <div className='card-body d-flex justify-content-between align-items-start flex-column'>
              <table className='table table-bordered text-center'>
                <tbody>
                  <tr>
                    <td>
                      <div className='d-flex flex-column'>
                        <div className='m-0'>
                          {showPulseRingLt && <span className='pulse-ring border-5 '></span>}
                          <span className='fw-semibold fs-6 text-black-400'>LT Forward Time</span>
                        </div>
                        <span className='fw-semibold fs-3x text-gray-800 '>
                          {Math.floor(spanLtValues.ltForwardTime / 60000)}
                        </span>
                      </div>
                    </td>
                    <td>
                      {' '}
                      <div className='d-flex flex-column'>
                        <div className='m-0'>
                          {showPulseRingLt && <span className='pulse-ring border-5 '></span>}
                          <span className='fw-semibold fs-6 text-black-400'>LT Reverse Time </span>
                        </div>
                        <span className='fw-semibold fs-3x text-gray-800 '>
                          {Math.floor(spanLtValues.ltReverseTime / 60000)}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='col-lg-2'>
          <div className='card card-stretch-80 card-bordered mb-5'>
            <div className='card-body d-flex justify-content-between align-items-start flex-column'>
              <table className='table table-bordered text-center'>
                <tbody>
                  <tr>
                    <td>
                      <div className='d-flex flex-column'>
                        <div className='m-0'>
                          {showPulseRingLt && <span className='pulse-ring border-5 '></span>}
                          <span className='fw-semibold fs-6 text-black-400'>LT Brake Count </span>
                        </div>
                        <span className='fw-semibold fs-3x text-gray-800 '>
                          {spanLtValues.ltFwdCount+spanLtValues.ltRevCount}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className='row g-5'>
        <div className='col-lg-4'>
          <div className='card card-stretch-80 card-bordered mb-5'>
            <DailySummaryWidget
              className='h-md-50 mb-5'
              title='Hoist Travel '
              chartId='ch1'
              type='ht'
              refreshEnabled={refreshEnabled}
              chartData={htChartData}
            />
          </div>
        </div>
        <div className='col-lg-4'>
          <div className='card card-stretch-80 card-bordered mb-5'>
            <DailySummaryWidget
              className='h-md-50 mb-5'
              title='Cross Travel'
              chartId='ch2'
              type='ct'
              refreshEnabled={refreshEnabled}
              chartData={ctChartData}
            />
          </div>
        </div>
        <div className='col-lg-4'>
          <div className='card card-stretch-80 card-bordered mb-5'>
            <DailySummaryWidget
              className='h-md-50 mb-5'
              title='Long Travel'
              chartId='ch3'
              type='lt'
              refreshEnabled={refreshEnabled}
              chartData={ltChartData}
            />
          </div>
        </div>
      </div>

      <div className='row g-5'>
        <div className='col-lg-4'>
          <div className='card card-stretch-80 card-bordered mb-5'>
            <div
              className={`card-body d-flex justify-content-between align-items-start flex-column ${
                htAlarmStatus === 'OH2' ? 'bg-danger' : ''
              }`}
            >
              <div className='d-flex flex-column'>
                <div className='m-0'>
                  {showPulseRingLt && <span className='pulse-ring border-5 '></span>}
                  <span className='fw-semibold fs-6 text-black-400'>HT alarm </span>
                </div>
                <span className='fw-semibold fs-3x text-gray-800 '>{htAlarmStatus}</span>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-4'>
          <div className='card card-stretch-80 card-bordered mb-5'>
            <div
              className={`card-body d-flex justify-content-between align-items-start flex-column ${
                ctAlarmStatus === 'OH2' ? 'bg-danger' : ''
              }`}
            >
              {' '}
              <div className='d-flex flex-column'>
                <div className='m-0'>
                  {showPulseRingLt && <span className='pulse-ring border-5 '></span>}
                  <span className='fw-semibold fs-6 text-black-400'>CT alarm </span>
                </div>
                <span className='fw-semibold fs-3x text-gray-800 '>{ctAlarmStatus}</span>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-4'>
          <div className='card card-stretch-80 card-bordered mb-5'>
            <div
              className={`card-body d-flex justify-content-between align-items-start flex-column ${
                ltAlarmStatus === 'OH2' ? 'bg-danger' : ''
              }`}
            >
              {' '}
              <div className='d-flex flex-column'>
                <div className='m-0'>
                  {showPulseRingLt && <span className='pulse-ring border-5 '></span>}
                  <span className='fw-semibold fs-6 text-black-400'>LT alarm </span>
                </div>
                <span className='fw-semibold fs-3x text-gray-800 '>{ltAlarmStatus}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row g-5'>
        <div className='col-lg-4'>
          <div className='card card-stretch-80 card-bordered mb-5'>
            <div className='card-body d-flex justify-content-between align-items-start flex-column'>
              <div className='d-flex flex-column'>
                <div className='m-0'>
                  {showPulseRingHt && <span className='pulse-ring border-5 '></span>}
                  <span className='fw-semibold fs-4 text-black-400'>HT Live Data </span>
                </div>
                <table>
                  <tbody>
                    {Object.entries(rawHtData).map(([key, value], index) => (
                      <tr key={index}>
                        <td>{key}</td>
                        <td>{JSON.stringify(value)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-4'>
          <div className='card card-stretch-80 card-bordered mb-5'>
            <div className='card-body d-flex justify-content-between align-items-start flex-column'>
              <div className='d-flex flex-column'>
                <div className='m-0'>
                  {showPulseRingHt && <span className='pulse-ring border-5 '></span>}
                  <span className='fw-semibold fs-4 text-black-400'>CT Live Data </span>
                </div>
                <table>
                  <tbody>
                    {Object.entries(rawCtData).map(([key, value], index) => (
                      <tr key={index}>
                        <td>{key}</td>
                        <td>{JSON.stringify(value)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-4'>
          <div className='card card-stretch-80 card-bordered mb-5'>
            <div className='card-body d-flex justify-content-between align-items-start flex-column'>
              <div className='d-flex flex-column'>
                <div className='m-0'>
                  {showPulseRingHt && <span className='pulse-ring border-5 '></span>}
                  <span className='fw-semibold fs-4 text-black-400'>LT Live Data </span>
                </div>
                <table>
                  <tbody>
                    {Object.entries(rawLtData).map(([key, value], index) => (
                      <tr key={index}>
                        <td>{key}</td>
                        <td>{JSON.stringify(value)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
