import React, {FC, useEffect, useState} from 'react'
import Pagination from './Pagination'
// @ts-ignore
import * as XLSX from 'xlsx/xlsx.mjs'
import {Card, Table} from 'react-bootstrap'
import moment from 'moment'
import {paginate, paginate1, removeIdAndAddSrNo} from '../../../util/paginate'
import {supabase} from '../../../supabaseClient'
import {KTIcon} from '../../../../_metronic/helpers'
import ExcelJS from 'exceljs'
import _ from 'lodash'
import {useAuth} from '../../auth'

interface DeviceHealthParams {
  device_id: string
  ht_cycle: number
  ct_cycle: number
  lt_cycle: number
  ht_brakes: number
  lt_brakes: number
  ct_brakes: number
  ht_trips: number
  ct_trips: number
  lt_trips: number
}

const DeviceHealthReset: FC = () => {
  const [devices, setDevices] = useState(null)
  const [dataFetched, setDataFetched] = useState(false) // Track data fetching completion
  const [deviceHealthParams, setDeviceHealthParams] = useState<DeviceHealthParams[] | null>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [paginatedData, setPaginatedData] = useState([])
  const {currentUser, logout} = useAuth()
  const [deviceList, setDeviceList] = useState<any>([])
  const [selectedDevice, setSelectedDevice] = useState<any>(0)

  const pageSize = 10
  // @ts-ignore
  useEffect(() => {
    function isWithinPercentageRange(
      a: number,

      min: number,
      max: number
    ) {
      // const lowerThreshold = b * (minPercentage / 100)
      // const upperThreshold = b * (maxPercentage / 100)

      if (a >= max) {
        return 'critical'
      } else if (a >= min && a <= max) {
        return 'warning'
      } else {
        return 'normal'
      }
    }

    const fetchDevices = async () => {
      let result
      if (currentUser?.role === 'customer-admin' || currentUser?.role === 'customer-user') {
        result = await supabase
            .from('device')
            .select('id,device_name')
            .eq('org_id', currentUser?.org_id)
      } else {
        result = await supabase.from('device').select('id,device_name')
      }

      if (result.error) console.error('Error fetching devices:', result.error)
      else {
        // @ts-ignore
        setDeviceList(result.data)
      }
    }


    fetchDevices()
    setSelectedDevice(window.localStorage.getItem('selectedDevice') || 0)

    getResult(window.localStorage.getItem('selectedDevice') || 0)


  }, [])

  async function getResult(device_id: any) {
    const result = []

    // Use for...of loop to handle async operations properly
    //   console.log(devices_param)
    let {data: devices, error} = await supabase
      .from('device_health_param_data')
      .select(`ht_cycle,
            ct_cycle,
            lt_cycle,
            ht_brakes,
            lt_brakes,
            ct_brakes,
            ht_trips,
            ct_trips,
            lt_trips`)
      .eq('device_id', device_id)
    let { data: totals } = await supabase
      .rpc('get_device_health_param_sums', { device_id });

    if (error) {
      console.error('Error fetching device health parameter sums:', error);
    } else {
      console.log('Aggregated sums:', totals);
      // Access individual sums like totals[0].ht_cycle_sum, etc.
    }

    console.log('devices--------------', _.get(devices, '[0]'))
    // @ts-ignore
    setDeviceHealthParams(updateKeyNames(totals) );
    return _.get(devices, '[0]')


// console.log('result-----------------',result)
//       return result
  }

  function updateKeyNames(dataArray: any[]) {
    return dataArray.map((item) => {
      return Object.keys(item).reduce((acc, key) => {
        // Remove "_sum" from the key
        const newKey = key.replace('_sum', '')
        // Assign the value to the new key
        // @ts-ignore
        acc[newKey] = item[key]
        return acc
      }, {})
    })
  }

  async function resetDeviceHealthParam(device_id: any,column_name:any) {


    let ins_result = await supabase.from('device_health_param_reset_log').insert({
      device_id: device_id,
      health_param_name: column_name,
      created_by: currentUser?.id
    });
    let result = await supabase.from('device_health_param_raw_data').update({
         [column_name] : 0
     }).eq('device_id', device_id)
    getResult(device_id)
  }
  // useEffect(() => {
  //   if (dataFetched) {
  //     console.log('deviceHealthParams use effecr called', deviceHealthParams)
  //    // let paginateltData = paginate1(deviceHealthParams, currentPage, pageSize);
  //
  //     // @ts-ignore
  //     // setPaginatedData(deviceHealthParams)
  //     console.log('paginateltData----------------', deviceHealthParams)
  //   }
  // }, [deviceHealthParams, currentPage, dataFetched]);
  // console.log('deviceHealthParams%%%%%%%%%%%%%', deviceHealthParams)
  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  // @ts-ignore

  // @ts-ignore
  // let _tmp_webstorm_ = users
  const length = deviceHealthParams?.length
  // deviceHealthParams.map((user:any, index) => (
  //   console.log('user++++++++++++++++++++++++++++++', user)
  // ));
  // console.log('deviceHealthParams%#############', deviceHealthParams)
  //
  if (!deviceList) {
    return <p>Loading...</p>
  }
  // @ts-ignore
  return (
    <div>
      <div className='row gy-5 g-xl-8'>
        {/* begin::Col */}
        <div className='col-xl-12'>
          <div className='d-flex my-2'>
            <select
              name='status'
              data-control='select2'
              data-hide-search='true'
              className='form-select form-select-white form-select-sm w-125px'
              value={window.localStorage.getItem('selectedDevice') || 0}
              onChange={(e) => {
                setSelectedDevice(e.target.value)
                getResult(e.target.value)
                window.localStorage.setItem('selectedDevice', e.target.value)
                // setRefreshEnabled(true)
              }}
            >
              <option key='0' value='Select Crane'>
                Select Crane
              </option>
              {deviceList.map((device: any) => (
                <option key={device.id} value={device.id}>
                  {device.device_name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <div className='card-toolbar'></div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            <Table className='table table-bordered gy-7 gs-7'>
              <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                <th scope="col"  className='min-w-25px rounded-start'> Device Health Param</th>
                <th scope="col"  className='min-w-25px text-center '> Device Health Data</th>

                <th scope="col"  className='min-w-125px text-center'>Action
                </th>
              </tr>
              </thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                <td>HT Cycle</td>
                <td>{_.get(deviceHealthParams,'[0].ht_cycle')}</td>
                <td><button type="button" className="btn btn-primary" onClick={
                  (e) => {
                  e.preventDefault()
                    console.log('selectedDevice',selectedDevice)
                  resetDeviceHealthParam(selectedDevice,'ht_cycle')
                }}
                 >Reset</button> </td>
              </tr>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                <td>HT Brakes</td>
                <td>{_.get(deviceHealthParams,'[0].ht_brakes')}</td>
                <td><button type="button" className="btn btn-primary" onClick={
                  (e) => {
                    e.preventDefault()
                    console.log('selectedDevice',selectedDevice)
                    resetDeviceHealthParam(selectedDevice,'ht_brakes')
                  }}
                >Reset</button></td>
              </tr>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                <td>HT Trips</td>
                <td>{_.get(deviceHealthParams,'[0].ht_trips')}</td>
                <td><button type="button" className="btn btn-primary" onClick={
                  (e) => {
                    e.preventDefault()
                    console.log('selectedDevice',selectedDevice)
                    resetDeviceHealthParam(selectedDevice,'ht_trips')
                  }}
                >Reset</button></td>
              </tr>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                <td>CT Cycle</td>
                <td>{_.get(deviceHealthParams,'[0].ct_cycle')}</td>
                <td><button type="button" className="btn btn-primary" onClick={
                  (e) => {
                    e.preventDefault()
                    console.log('selectedDevice',selectedDevice)
                    resetDeviceHealthParam(selectedDevice,'ct_cycle')
                  }}
                >Reset</button></td>
              </tr>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                <td>CT Brakes</td>
                <td>{_.get(deviceHealthParams,'[0].ct_brakes')}</td>
                <td><button type="button" className="btn btn-primary" onClick={
                  (e) => {
                    e.preventDefault()
                    console.log('selectedDevice',selectedDevice)
                    resetDeviceHealthParam(selectedDevice,'ct_brakes')
                  }}
                >Reset</button></td>
              </tr>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                <td>CT Trips</td>
                <td>{_.get(deviceHealthParams,'[0].ct_trips')}</td>
                <td><button type="button" className="btn btn-primary" onClick={
                  (e) => {
                    e.preventDefault()
                    console.log('selectedDevice',selectedDevice)
                    resetDeviceHealthParam(selectedDevice,'ct_trips')
                  }}
                >Reset</button></td>
              </tr>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                <td>LT Cycle</td>
                <td>{_.get(deviceHealthParams,'[0].lt_cycle')}</td>
                <td><button type="button" className="btn btn-primary" onClick={
                  (e) => {
                    e.preventDefault()
                    console.log('selectedDevice',selectedDevice)
                    resetDeviceHealthParam(selectedDevice,'lt_cycle')
                  }}
                >Reset</button>
                </td>
              </tr>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                <td>LT Brakes</td>
                <td>{_.get(deviceHealthParams,'[0].lt_brakes')}</td>
                <td><button type="button" className="btn btn-primary" onClick={
                  (e) => {
                    e.preventDefault()
                    console.log('selectedDevice',selectedDevice)
                    resetDeviceHealthParam(selectedDevice,'lt_brakes')
                  }}
                >Reset</button></td>
              </tr>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                <td>LT Trips</td>
                <td>{_.get(deviceHealthParams,'[0].lt_trips')}</td>
                <td><button type="button" className="btn btn-primary" onClick={
                  (e) => {
                    e.preventDefault()
                    console.log('selectedDevice',selectedDevice)
                    resetDeviceHealthParam(selectedDevice,'lt_trips')
                  }}
                >Reset</button>
                </td>
              </tr>


            </Table>
            <Pagination
              items={length}
              currentPage={currentPage}
              pageSize={pageSize}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
        {/* end::Body */}
      </div>
    </div>
  )
}

export default DeviceHealthReset
