// pages/index.js

import { useEffect, useState } from 'react';
import {supabase} from "../../../supabaseClient";
import {Navigate, Routes} from 'react-router-dom'
// import {ResetPassword} from './components/ResetPassword'
import clsx from 'clsx'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from './../core/_requests'
import {useAuth} from './../core/Auth'
import {Simulate} from 'react-dom/test-utils'
import error = Simulate.error

export  function ResetPassword() {
  const [accessToken, setAccessToken] = useState(null);
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(null)
  const [success, setSuccess] = useState(null);
  const {saveAuth, setCurrentUser} = useAuth()
  const [error, setError] = useState(null);  // State variable to store error message
  const initialValues = {
    password: '',
    repeat_password:'',
  }

  const forgotPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
    repeat_password: Yup.string()
      .equals([Yup.ref('password')], 'Passwords must match')
  })

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setError(null);  // Reset error state before submitting
      if (accessToken && values?.password) {
       const user= await supabase.auth.getUser(accessToken)
        const {error: updateError} = await supabase.auth.updateUser({
          password: values.password,
        });

        if (updateError?.message) {
          // @ts-ignore
          setError(updateError.message);
        }else {
          // @ts-ignore
          setSuccess('Password updated successfully.');
        }
      }
      // Password update successful

    },
  })
  useEffect(() => {

    const fetchData = async () => {
      if (typeof window !== 'undefined') {
        const hash = window.location.hash.substring(1);
        const token = extractAccessToken(hash);

        const supaauth = token ? await supabase.auth.getUser(token) : null;
        console.log(supaauth);

        if(supaauth?.error || supaauth === null){
          // @ts-ignore
          setError("Something went wrong. Please try again.");
        }else{
          // @ts-ignore
          setMessage("Welcome "+supaauth.data.user.email)
        }
        // @ts-ignore
        setAccessToken(token);
      }
    };

    fetchData();


  }, []);

  // utils/extractToken.js

  function extractAccessToken(url:string) {
    // const hashIndex = url.indexOf('#');
    // if (hashIndex === -1) return null;

    // const hashParams = url.substring(hashIndex + 1);
    const params = new URLSearchParams(url);

    return params.get('access_token');
  }

  if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        {error} <br/>
        <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link>
      </div>
    );
  }

  if (success) {
    return (
      <div className="alert alert-success" role="alert">
        {success}  <Link to='/auth/login'> Login </Link>
      </div>
    );
  }
  return (

    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>K2 Crane</h1>

        <h2 className='text-dark fw-bolder mb-2'>Reset Password</h2>

        <h2 className='text-dark fw-bolder mb-2'>{message}</h2>
      </div>
      {/* begin::Heading */}
      {/* If there's an error, show the error message */}
      {error && (
        <div className='alert alert-danger' role='alert'>
          {error}
        </div>
      )}



      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Repeat Password</label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('repeat_password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.repeat_password && formik.errors.repeat_password,
            },
            {
              'is-valid': formik.touched.repeat_password && !formik.errors.repeat_password,
            }
          )}
        />
        {formik.touched.repeat_password && formik.errors.repeat_password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.repeat_password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
