import {PageTitle} from "../../../_metronic/layout/core";
import {useIntl} from "react-intl";
import React from 'react'
import DeviceHealth from '../../modules/widgets/components/DeviceHealth'


const DeviceHealthPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]} >
        Device Health Monitoring
      </PageTitle>
      <div>
        <div className='g-5 gx-xxl-8'>
          <DeviceHealth/>
        </div>
      </div>
    </>

  )
}

const DeviceHealthWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DeviceHealthPage/>
    </>
  )
}

export {DeviceHealthWrapper}
