import _ from 'lodash';
import {Component} from 'react'

class Pagination extends Component<{
  items: any
  pageSize: any
  currentPage: any
  onPageChange: any
}> {
  render() {
    let {items, pageSize, currentPage, onPageChange} = this.props
    const pageCount = Math.ceil(items / pageSize)
    if (pageCount === 1) return null
    const startPageCount = currentPage - 2 < 1 ? 1 : currentPage - 2
    const endPageCount = currentPage + 2 > pageCount ? pageCount : currentPage + 2
    const pages = _.range(startPageCount, endPageCount)

    return (
      <nav aria-label='Page navigation example'>
        <ul className='pagination'>
          <li className='page-item'>
            <a
              className='page-link'
              onClick={() => onPageChange(currentPage > 1 ? currentPage - 1 : 1)}
              aria-label='Previous'
            >
              <span aria-hidden='true'>&laquo;</span>
              <span className='sr-only'>Previous</span>
            </a>
          </li>
          {pages.map((page) => (
            <li key={page} className='page-item'>
              <a
                className={page === currentPage ? 'page-link active' : 'page-link'}
                onClick={() => onPageChange(page)}
              >
                {page}
              </a>
            </li>
          ))}
          <li className='page-item'>
            <a
              className='page-link'
              onClick={() => onPageChange(currentPage < pageCount ? currentPage + 1 : currentPage)}
              aria-label='Next'
            >
              <span aria-hidden='true'>&raquo;</span>
              <span className='sr-only'>Next</span>
            </a>
          </li>
        </ul>
      </nav>
    )
  }
}

export default Pagination
