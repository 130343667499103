import React, {FC, useEffect, useState} from 'react'
import Pagination from './Pagination'
// @ts-ignore
import * as XLSX from 'xlsx/xlsx.mjs'
import {Card, Table} from 'react-bootstrap'
import moment from 'moment'
import {paginate, paginate1, removeIdAndAddSrNo} from '../../../util/paginate'
import {supabase} from '../../../supabaseClient'
import {KTIcon} from '../../../../_metronic/helpers'
import ExcelJS from 'exceljs'
import _ from 'lodash'
import {useAuth} from '../../auth'
import {getDeviceData} from '../../apps/device-data/_requests'

interface AlarmHistoryDataProps {
  id: number
  device_id: string
  alarm : string
  created_at : string
  device_time : string
  motion : string
  alarm_code : string
}

const AlarmHistoryData: FC = () => {
  const [devices, setDevices] = useState(null)
  const [dataFetched, setDataFetched] = useState(false) // Track data fetching completion
  const [alarmHistory, setAalarmHistory] = useState<AlarmHistoryDataProps[] >([])
  const [currentPage, setCurrentPage] = useState(1)
  const [paginatedData, setPaginatedData] = useState([])
  const [selectedDevice, setSelectedDevice] = useState<any>(0)
  const {currentUser, logout} = useAuth()
  const [deviceList, setDeviceList] = useState<any>([])
  const [isLoading, setIsLoading] = useState(true)

  const pageSize = 10
  // @ts-ignore
  useEffect(() => {

    const fetchDevices = async () => {
      let result
      if (currentUser?.role === 'customer-admin' || currentUser?.role === 'customer-user') {
        result = await supabase
          .from('device')
          .select('id,device_name')
          .eq('org_id', currentUser?.org_id)
      } else {
        result = await supabase.from('device').select('id,device_name')
      }

      if (result.error) console.error('Error fetching devices:', result.error)
      else {
        // @ts-ignore
        setDeviceList(result.data)
      }
    }
    fetchDevices()
    setSelectedDevice(window.localStorage.getItem('selectedDevice'))
    fetchData(window.localStorage.getItem('selectedDevice') || '0')


  }, [])

  const fetchData = async (selectedDevice: string) => {
    try {
      // @ts-ignore
      let {data: alarmData, error} = await supabase
        .from('action_data')
        .select(`*`)
        .eq('device_id', selectedDevice)
        .neq('alarm_code', 'NO')
        .order('created_at', { ascending: false })
        .limit(20)
      // @ts-ignore
      alarmData = alarmData.map(item => {

        const createdAtKolkata = moment(item.created_at).add(5.5, 'hours');
        item.created_at = createdAtKolkata.format('DD-MMM-Y HH:mm:ss');
        return item;
      });
      if (error) console.log('error', error)
      else {
        // @ts-ignore

        // @ts-ignore

        // @ts-ignore
        // setDevices('sdsdsdsdsd',alarmHistory)


        // @ts-ignore
        setAalarmHistory(alarmData)
        setDataFetched(true)
        setIsLoading(false)
      }
    } catch (e) {
      console.log('error', e)
    }




    // setDataUpdated(dataUpdated+1);
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }


  const length = alarmHistory?.length
  // if (!isLoading) {
  //   return <p>Loading...</p>
  // }
  // @ts-ignore
  // @ts-ignore
  return (
    <div>
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <div className='card-toolbar'></div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          <div className='row gy-5 g-xl-4'>
            {/* begin::Col */}

            <div className='col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
              <div className='d-flex my-2'>
                <select
                  name='status'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-select form-select-solid form-select-lg'
                  value={selectedDevice}
                  onChange={(e) => {
                    setSelectedDevice(e.target.value)
                    window.localStorage.setItem('selectedDevice', e.target.value)
                    // fetchActionData(e.target.value,motionValue)
                    fetchData(e.target.value)
                  }}
                >
                  <option key='0' value='Select Crane'>
                    Select Crane
                  </option>
                  {deviceList.map((device: any) => (
                    <option key={device.id} value={device.id}>
                      {device.device_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* begin::Table container */}
          <div className='table-responsive'>
            <Table className='table table-bordered gy-7 gs-7'>
              <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                <th scope="col" className='min-w-25px rounded-start'> Sr. No</th>
                <th scope="col" className='min-w-125px'>
                  Motion
                </th>
                <th scope="col" className='min-w-125px'>
                  Alarm Code
                </th>
                <th scope="col" className='min-w-125px'>
                  Generated At
                </th>

              </tr>

              </thead>
              {dataFetched ? (
                <tbody>

                {alarmHistory.map((alarm: any, index: number) => (
                  <tr>
                    <td >{index+1}</td>
                    <td>

                          {alarm.motion}
                        </td>
                    <td>
                        <span
                          className={`badge ${
                            alarm.alarm_code === 'NO'
                              ? 'badge-success': 'badge-danger'
                          }`}
                        >
                          {alarm.alarm_code}
                        </span>
                    </td>
                    <td>
                      {alarm.created_at}
                    </td>

                  </tr>
                  ))}
                  </tbody>
              ) : (
                <tbody>
                <tr>
                  <td colSpan={10}>Loading...</td>
                </tr>
                </tbody>
              )}

            </Table>

          </div>
        </div>
        {/* end::Body */}
      </div>
    </div>
  )
}

export default AlarmHistoryData
