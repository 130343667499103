import React, {FC, useEffect, useState} from 'react'
import Pagination from './Pagination'
// @ts-ignore
import * as XLSX from 'xlsx/xlsx.mjs'
import {Card, Table} from 'react-bootstrap'
import moment from 'moment'
import {paginate, paginate1, removeIdAndAddSrNo} from '../../../util/paginate'
import {supabase} from '../../../supabaseClient'
import {KTIcon} from '../../../../_metronic/helpers'
import ExcelJS from 'exceljs'
import _ from 'lodash'
import {useAuth} from '../../auth'
import {USER_ROLES} from '../../../util/roles'

interface DeviceHealthParams {
  device_id: string
  critical_param: any[] // Define the actual type for critical_param
  warning_param: any[] // Define the actual type for warning_param
  normal_param: any[] // Define the actual type for normal_param
}

const DeviceHealth: FC = () => {
  const [devices, setDevices] = useState(null)
  const [dataFetched, setDataFetched] = useState(false) // Track data fetching completion
  const [deviceHealthParams, setDeviceHealthParams] = useState<DeviceHealthParams[] | null>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [paginatedData, setPaginatedData] = useState([])
  const {currentUser, logout} = useAuth()

  const pageSize = 10
  // @ts-ignore
  useEffect(() => {
    function isWithinPercentageRange(
      a: number,

      min: number,
      max: number
    ) {
      // const lowerThreshold = b * (minPercentage / 100)
      // const upperThreshold = b * (maxPercentage / 100)

      if (a >= max) {
        return 'critical'
      } else if (a >= min && a <= max) {
        return 'warning'
      } else {
        return 'normal'
      }
    }

    async function getResult(devices_param: any[]) {
      const result = []

      // Use for...of loop to handle async operations properly
      debugger
      for (const device of devices_param) {
        if( _.isUndefined(_.get(device,'device.device_name'))){
          continue
        }
        console.log(devices_param)
        // let {data: devices, error} = await supabase
        //   .from('device_health_param_data')
        //   .select(`ht_cycle,
        //     ct_cycle,
        //     lt_cycle,
        //     ht_brakes,
        //     lt_brakes,
        //     ct_brakes,
        //     ht_trips,
        //     ct_trips,
        //     lt_trips`)
        //   .eq('device_id', device.device_id)
        let { data: totals } = await supabase
          .rpc('get_device_health_param_sums', { device_id:device.device_id });
        let devices = updateKeyNames(totals)
        console.log('devices--------------', _.get(devices, '[0]'))
        const params = []
        const critical_param = []
        const warning_param = []

        for (const key of Object.keys(devices ? devices[0] : [])) {

          const value =_.get(devices, '[0].'+ key)
          const warning_value = _.get(device, key+'_warning')
          const critical_value = _.get(device, key+'_critical')

          if (isWithinPercentageRange(value, warning_value, critical_value) === 'critical') {
            params.push({key, value, status: 'critical'})
          } else if (isWithinPercentageRange(value, warning_value, critical_value) === 'warning') {
            params.push({key, value, status: 'warning'})
          } else {
            params.push({key, value, status: 'normal'})
          }
        }

        console.log('params------------', devices)
        const tdata = {
          device_id:  _.get(device,'device.device_name'),
          ...Object.assign(
            {},
            ...params.map((param) => ({
              [param.key]: {
                value: param.value,
                status: param.status,
              },
            }))
          ),
        }

        result.push(tdata)
      }

      return result
    }

    function updateKeyNames(dataArray: any[]) {
      return dataArray.map((item) => {
        return Object.keys(item).reduce((acc, key) => {
          // Remove "_sum" from the key
          const newKey = key.replace('_sum', '')
          // Assign the value to the new key
          // @ts-ignore
          acc[newKey] = item[key]
          return acc
        }, {})
      })
    }

    const fetchData = async () => {
      try {

        // if (currentUser?.role === 'customer-admin' || currentUser?.role === 'customer-user') {
        //   result = await supabase
        //     .from('device')
        //     .select('id,device_name')
        //     .eq('org_id', currentUser?.org_id)
        // } else {
        //   result = await supabase.from('device').select('id,device_name')
        // }
        let query = supabase
          .from('device_health_param')
          .select(`*,device(id,device_name)`);

        if (currentUser?.role === USER_ROLES.CUSTOMER_ADMIN || currentUser?.role === USER_ROLES.CUSTOMER_USER) {
          query = query.eq('device.org_id', currentUser?.org_id);
        }
        let { data: devices, error } = await query;
        // console.log(data)

        if (error) console.log('error', error)
        else {
          // @ts-ignore
          let result = []

          // @ts-ignore
          result = await getResult(devices)

          // @ts-ignore
          // setDevices('sdsdsdsdsd',deviceHealthParams)

          // @ts-ignore
          setDevices(devices)
          // @ts-ignore
          setDeviceHealthParams(result)

          setDataFetched(true)
        }
      } catch (e) {
        console.log('error', e)
      }
    }
    fetchData()
  }, [])

  // useEffect(() => {
  //   if (dataFetched) {
  //     console.log('deviceHealthParams use effecr called', deviceHealthParams)
  //    // let paginateltData = paginate1(deviceHealthParams, currentPage, pageSize);
  //
  //     // @ts-ignore
  //     // setPaginatedData(deviceHealthParams)
  //     console.log('paginateltData----------------', deviceHealthParams)
  //   }
  // }, [deviceHealthParams, currentPage, dataFetched]);
  // console.log('deviceHealthParams%%%%%%%%%%%%%', deviceHealthParams)
  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  // @ts-ignore

  // @ts-ignore
  // let _tmp_webstorm_ = users
  const length = deviceHealthParams?.length
  // deviceHealthParams.map((user:any, index) => (
  //   console.log('user++++++++++++++++++++++++++++++', user)
  // ));
  // console.log('deviceHealthParams%#############', deviceHealthParams)
  //
  if (!deviceHealthParams) {
    return <p>Loading...</p>
  }
  // @ts-ignore
  return (
    <div>
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <div className='card-toolbar'></div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            <Table className='table table-bordered gy-7 gs-7'>
              <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                <th scope="col"  className='min-w-125px rounded-start'> Device Name</th>
                <th scope="col"  colSpan={3} className='min-w-125px text-center'>
                  {' '}
                  HT
                </th>
                <th scope="col"  colSpan={3} className='min-w-125px text-center'>
                  CT
                </th>
                <th scope="col"  colSpan={3} className='min-w-125px text-center'>
                  LT
                </th>
              </tr>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                <th scope="col"  className='min-w-25px rounded-start'> </th>
                <th scope="col"  className='min-w-125px'> Cycles</th>
                <th scope="col"  className='min-w-125px'>Brakes</th>
                <th scope="col"  className='min-w-125px'>Trips</th>
                <th scope="col"  className='min-w-125px'> Cycles</th>
                <th scope="col"  className='min-w-125px'>Brakes</th>
                <th scope="col"  className='min-w-125px'>Trips</th>
                <th scope="col"  className='min-w-125px'> Cycles</th>
                <th scope="col"  className='min-w-125px'>Brakes</th>
                <th scope="col"  className='min-w-125px'>Trips</th>
              </tr>
              </thead>
              {dataFetched ? (
                <tbody>
                {deviceHealthParams.map((user: any, index: number) => (
                  <tr>
                    <td>{user.device_id}</td>
                    <td> <span
                      className={`badge ${
                        user.ht_cycle.status === 'critical'
                          ? 'badge-danger'
                          : user.ht_cycle.status === 'warning'
                            ? 'badge-warning'
                            : 'badge-success'
                      }`}
                    >
                          {user.ht_cycle.value}
                        </span></td>
                    <td>
                        <span
                          className={`badge ${
                            user.ht_brakes.status === 'critical'
                              ? 'badge-danger'
                              : user.ht_brakes.status === 'warning'
                                ? 'badge-warning'
                                : 'badge-success'
                          }`}
                        >
                          {user.ht_brakes.value}
                        </span>
                    </td>
                    <td>
                        <span
                          className={`badge ${
                            user.ht_trips.status === 'critical'
                              ? 'badge-danger'
                              : user.ht_trips.status === 'warning'
                                ? 'badge-warning'
                                : 'badge-success'
                          }`}
                        >
                          {user.ht_trips.value}
                        </span>
                    </td>
                    <td>
                        <span
                          className={`badge ${
                            user.ct_cycle.status === 'critical'
                              ? 'badge-danger'
                              : user.ct_cycle.status === 'warning'
                                ? 'badge-warning'
                                : 'badge-success'
                          }`}
                        >
                          {user.ct_cycle.value}
                        </span>
                    </td>
                    <td>
                        <span
                          className={`badge ${
                            user.ct_brakes.status === 'critical'
                              ? 'badge-danger'
                              : user.ct_brakes.status === 'warning'
                                ? 'badge-warning'
                                : 'badge-success'
                          }`}
                        >
                          {user.ct_brakes.value}
                        </span>
                    </td>
                    <td>
                        <span
                          className={`badge ${
                            user.ct_trips.status === 'critical'
                              ? 'badge-danger'
                              : user.ct_trips.status === 'warning'
                                ? 'badge-warning'
                                : 'badge-success'
                          }`}
                        >
                          {user.ct_trips.value}
                        </span>
                    </td>
                    <td>
                        <span
                          className={`badge ${
                            user.lt_cycle.status === 'critical'
                              ? 'badge-danger'
                              : user.lt_cycle.status === 'warning'
                                ? 'badge-warning'
                                : 'badge-success'
                          }`}
                        >
                          {user.lt_cycle.value}
                        </span>
                    </td>
                    <td>
                        <span
                          className={`badge ${
                            user.lt_brakes.status === 'critical'
                              ? 'badge-danger'
                              : user.lt_brakes.status === 'warning'
                                ? 'badge-warning'
                                : 'badge-success'
                          }`}
                        >
                          {user.lt_brakes.value}
                        </span>
                    </td>
                    <td>
                        <span
                          className={`badge ${
                            user.lt_trips.status === 'critical'
                              ? 'badge-danger'
                              : user.lt_trips.status === 'warning'
                                ? 'badge-warning'
                                : 'badge-success'
                          }`}
                        >
                          {user.lt_trips.value}
                        </span>
                    </td>
                  </tr>
                ))}
                </tbody>
              ) : (
                <tbody>
                <tr>
                  <td colSpan={10}>Loading...</td>
                </tr>
                </tbody>
              )}
              <tfoot>
              <tr>
                <td colSpan={7}></td>
                <td colSpan={3}>
                  <span className='badge  badge-danger'> Critical values</span> &nbsp;
                  <span className='badge  badge-warning'> Warning values</span>&nbsp;
                  <span className='badge  badge-success'> Normal values</span>
                </td>
              </tr>
              </tfoot>
            </Table>
            <Pagination
              items={length}
              currentPage={currentPage}
              pageSize={pageSize}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
        {/* end::Body */}
      </div>
    </div>
  )
}

export default DeviceHealth
