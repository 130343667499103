/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSubMain} from './AsideMenuItemWithSubMain'
import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from '../../../../app/modules/auth'

export function AsideMenuMain() {
  const intl = useIntl()
  const {currentUser, logout} = useAuth()

  return (
    <>

        {/*<AsideMenuItem*/}
        {/*    to='/dashboard'*/}
        {/*    title='Dashboard'*/}
        {/*    fontIcon='bi-house fs-2'*/}
        {/*    bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}*/}
        {/*    className='py-2'*/}
        {/*/>*/}
      <AsideMenuItem to='/liveData ' title='Live Monitoring' fontIcon='bi-speedometer2 fs-3' className='text-center' />
      <AsideMenuItem to='/device-dashboard' title='Device Dashboard' fontIcon='bi-speedometer2 fs-3' className='text-center' />

      <AsideMenuItemWithSubMain
        to='/report'
        title='report'
        fontIcon='bi-file-text'
      >
        <AsideMenuItem
          to='/report'
          title='Crane Summary'
          fontIcon='bi-bar-chart-line fs-2'
          bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          className='py-2'
        />
        <AsideMenuItem
          to='/alarmHistory'
          title='Alarm History'
          fontIcon='bi-bar-chart-line fs-2'
          bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          className='py-2'
        />
        <AsideMenuItem
          to='/deviceHealth'
          title='Health Reports'
          fontIcon='bi-clipboard2-pulse fs-2'
          bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          className='py-2'
        />
      </AsideMenuItemWithSubMain>


      {(currentUser?.role === 'superadmin' )  &&
      <AsideMenuItem
        to='/apps/user-management/users'
        title='Users'
        fontIcon='bi-people fs-2'
        bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        className='py-2'
      />
      }
        {(currentUser?.role === 'superadmin' || currentUser?.role === 'oem-admin')  &&<AsideMenuItem
        to='/apps/device-management/device'
        title='Devices'
        fontIcon='bi bi-device-ssd  fs-2'
        bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        className='py-2'
      />}

      {(currentUser?.role === 'superadmin' || currentUser?.role === 'oem-admin')  &&<AsideMenuItem
        to='/apps/department-management/dept'
        title='Departments'
        fontIcon='bi bi-device-ssd  fs-2'
        bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        className='py-2'
      />}
      {(currentUser?.role === 'superadmin' )  &&<AsideMenuItem
        to='/apps/org-management/org'
        title='Customers'
        fontIcon='bi bi-buildings fs-2'
        bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        className='py-2'
      />}

      {(currentUser?.role === 'superadmin' )  &&<AsideMenuItemWithSubMain
        to='/apps/health-param-management/health-param'
        title='Device Health Settings'
        fontIcon='bi-gear'
      ><AsideMenuItem
        to='/apps/health-param-management/health-param'
        title='Health Parameters'
        fontIcon='bi  bi-clipboard2-pulse fs-2'
        bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        className='py-2 text-center'
      />
        <AsideMenuItem
          to='/deviceHealthParamReset'
          title='Reset Device Health Data'
          fontIcon='bi  bi-clipboard2-pulse fs-2'
          bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          className='py-2 text-center'
        />
      </AsideMenuItemWithSubMain>}

    </>

  )
}
