import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {ReportWrapper} from '../pages/report/ReportWrapper'
import { GenerateReportWrapper} from '../pages/report/GenerateReportWrapper'
import {LiveDataWrapper} from '../pages/LiveData/LiveDataWrapper'
import {UsersWrapper} from '../pages/Users/UserWrapper'
import {DeviceHealthWrapper} from '../pages/DeviceHealth/DeviceHealthWrapper'
import {DeviceDashboardWrapper} from '../pages/dashboard/DeviceDashboardWrwpper'
import {AlarmHistoryWrapper} from '../pages/report/AlarmHistoryWrapper'
import {DeviceHealthParamResetWrapper} from '../pages/DeviceHealthParamReset/DeviceHealthParamResetWrapper'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const DevicesPage = lazy(() => import('../modules/apps/device-management/DevicePage'))
  const OrgPage = lazy(() => import('../modules/apps/org-management/OrgPage'))
  const DeptPage = lazy(() => import('../modules/apps/department-management/DeptPage'))
  const HealthParamPage = lazy(() => import('../modules/apps/health-param-management/HealthParamPage'))
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/liveData' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
          <Route path='report' element={<ReportWrapper />} />
        <Route path='liveData' element={<LiveDataWrapper />} />
        <Route path='generateReport' element={<GenerateReportWrapper />} />
        <Route path='deviceHealth' element={<DeviceHealthWrapper />} />
        <Route path='device-dashboard' element={<DeviceDashboardWrapper />} />
        <Route path='deviceHealthParamReset' element={<DeviceHealthParamResetWrapper />} />
       <Route path='alarmHistory' element={<AlarmHistoryWrapper />} />
        <Route path='alarmHistory' element={<AlarmHistoryWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/device-management/*'
          element={
            <SuspensedView>
              <DevicesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/org-management/*'
          element={
            <SuspensedView>
              <OrgPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/department-management/*'
          element={
            <SuspensedView>
              <DeptPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/health-param-management/*'
          element={
            <SuspensedView>
              <HealthParamPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
