import {FC, useEffect, useMemo, useRef, useState} from 'react'
import {KTIcon} from '../../../../helpers'
import {getCSSVariableValue} from '../../../../assets/ts/_utils'
import {useThemeMode} from '../../../layout/theme-mode/ThemeModeProvider'
import ApexCharts, {ApexOptions} from 'apexcharts'
import axios from 'axios'
import _ from 'lodash'
import {getDeviceData} from '../../../../../app/modules/apps/device-data/_requests'
import moment from 'moment/moment'

type Props = {
  className: string
  chartSize?: number
  chartLine?: number
  chartRotate?: number
  title?: string
  chartId?: string
  type?:string
  refreshEnabled:boolean
  chartData:any
}

const DailySummaryWidget: FC<Props> = ({
                                         className,
                                         chartSize = 70,
                                         chartLine = 11,
                                         chartRotate = 145,
                                         title,
                                         chartId,
                                         type,
                                         refreshEnabled,
                                         chartData: chartDataProp,
                                       }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const [chart, setChart] = useState<ApexCharts | null>(null);
  const [spanText, setSpanText] = useState('Initial Text');

  const chartData = useMemo(() => chartDataProp, [chartDataProp]);

  useEffect(() => {
    fetchData();
  }, [mode, type, refreshEnabled, chartData])

  function fetchData() {
    // const response = await getDeviceData(`${type}_data`,moment().subtract(2, 'days').format('YYYY-MM-DD'),moment().format('YYYY-MM-DD'))

    const lastObject = _.last(chartData);

    const countData = _.get(lastObject, 'count', 0);
    setSpanText('' + countData);
    refreshChart(lastObject);
  }
  const refreshChart = (data:any) => {
    if (!chartRef.current) {
      console.error('DailySummaryWidget: invalid chart reference')
      return;
    }

    let labels = [];

    if (type === 'ht') {
      labels = ['HT Up Count', 'HT Down Count', 'Speed Count'];
    } else if (type === 'ct') {
      labels = ['CT Right Count', 'CT Left Count', 'Speed Count'];
    } else {
      labels = ['LT Forward Count', 'LT Reverse Count', 'Speed Count'];
    }

    const dataSet=[Number(_.get(data,'fwd_count',0)),Number(_.get(data,'rev_count',0)),(Number(_.get(data,'fwd_ds_count',0))+Number(_.get(data,'rev_ds_count',0)))]
    // const chart = new ApexCharts(chartRef.current, getChartOptions(dataSet,labels))
    if (!chart) {
      const chartInstance = new ApexCharts(chartRef.current, getChartOptions(dataSet, labels))
      setChart(chartInstance);
      chartInstance.render();
    } else {
      chart.updateSeries(dataSet);
      chart.updateOptions({labels: labels});
    }
      // if (chart) {
      //   console.log('chart.render() not working');
      //    chart.render()
      // }

    return chart
  }

  return (

    <div className={`card card-flush ${className}`}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          {/* <div className='d-flex align-items-center'>
            <span  className='fs-4 fw-semibold  me-1 align-self-start'>{title}  Count Today </span>

            <span id='countvalaue' className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{spanText}</span>


          </div> */}
          <span className=' pt-1 fw-semibold fs-6'>{title}  Cycle</span>
        </div>
      </div>

      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
        <div className='d-flex flex-center me-5 pt-2'>
          <div
            id={chartId}
            ref={chartRef}
            style={{minWidth: chartSize + 'px', minHeight: chartSize + 'px'}}
            data-kt-size={chartSize}
            data-kt-line={chartLine}
          ></div>
        </div>


      </div>
    </div>
  )
}





function getChartOptions(data:number[],label:string[]){
return  {
  series: data,
  labels: label,
  chart: {
    type: 'donut',
  },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
    },
  }


}
export {DailySummaryWidget}
