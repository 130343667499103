/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTIcon} from '../../../helpers'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import {supabase} from '../../../../app/supabaseClient'
import moment from 'moment'
import _ from 'lodash'


type Props = {
  className: string
  chartHeight: string
  chartColor: string
}

const Device1Widget: React.FC<Props> = ({className, chartHeight, chartColor}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const [created_at, setCreatedAt] = useState(null)
  const [totalizer, setTotalizer] = useState(null)
  const [flow_rate, setFlowRate] = useState(null)
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartHeight, chartColor))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()
    async function getData() {

      let {data: flow_data, error} = await supabase
        .from('flow_data')
        .select('*')
        .eq('device_id', 1)
        .order('created_at', { ascending: false })
        .limit(1);
      if (flow_data) {
        // @ts-ignore
        const result = flow_data[0]
        setCreatedAt(result.created_at)
        setTotalizer(result.totalizer)
        setFlowRate(result.flow_rate)
      }
    }

     function setLiveData() {

      const channel = supabase
        .channel('deviceid1')
        .on(
          'postgres_changes',
          {
            event: 'INSERT',
            schema: 'public',
            table: 'flow_data',
            filter: 'device_id=eq.1',
          },
          (payload) => {
            console.log('Change received!', payload)
            const result = _.get(payload, 'new')
            setCreatedAt(_.get(payload, 'new.created_at'))
            setTotalizer(_.get(payload, 'new.totalizer'))
            setFlowRate(_.get(payload, 'new.flow_rate'))
          }
        )
        .subscribe()

    }

    getData()
    setLiveData()
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode])

  // @ts-ignore
  return (
    <div className={`card ${className}`}>
      {/* begin::Beader */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Plant 01 Ground Water</span>

          {/*<span className='text-muted fw-semibold fs-7'>Recent sales statistics</span>*/}
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body p-0 d-flex flex-column'>
        {/* begin::Stats */}
        <div className='card-p pt-5 bg-body flex-grow-1'>
          {/* begin::Row */}
          <div className='row g-0'>
            {/* begin::Col */}
            <div className='col mr-8'>
              {/* begin::Label */}
              <div className='fs-7 text-muted fw-semibold'>Last Updated At</div>
              {/* end::Label */}

              {/* begin::Stat */}
              <div className='d-flex align-items-center'>
                <div className='fs-4 fw-bold'>{moment(created_at).format('YYYY-MM-DD HH:mm:ss')}</div>
                <KTIcon iconName='electronic-clock' className='fs-5 text-success ms-1' />
              </div>
              {/* end::Stat */}
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}

          {/* begin::Row */}
          <div className='row g-0 mt-4'>
            {/* begin::Col */}
            <div className='col mr-8 '>
              {/* begin::Label */}
              <div className='fs-7 text-muted fw-semibold'>Flow Rate</div>
              {/* end::Label */}

              {/* begin::Stat */}
              <div className='d-flex align-items-center'>
                <div className='fs-4 fw-bold'>{flow_rate} LPM</div>
                {/*<KTIcon iconName='electronic-clock' className='fs-5 text-success ms-1' />*/}
              </div>
              {/* end::Stat */}
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}

          {/* begin::Row */}
          <div className='row g-0 mt-4'>
            {/* begin::Col */}
            <div className='col mr-8'>
              {/* begin::Label */}
              <div className='fs-7 text-muted fw-semibold'>Totalizer</div>
              {/* end::Label */}

              {/* begin::Stat */}
              <div className='d-flex align-items-center'>
                <div className='fs-4 fw-bold'> {totalizer} ㎥</div>
                {/*<KTIcon iconName='electronic-clock' className='fs-5 text-success ms-1' />*/}
              </div>
              {/* end::Stat */}
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
        </div>
        {/* end::Stats */}

        {/* begin::Chart */}
        <div
          ref={chartRef}
          className='mixed-widget-3-chart card-rounded-bottom'
          data-kt-chart-color={chartColor}
          style={{height: chartHeight}}
        ></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

const chartOptions = (chartHeight: string, chartColor: string): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-800')
  const strokeColor = getCSSVariableValue('--bs-gray-300')
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-' + chartColor + '-light')

  return {
    series: [
      {
        name: 'Net Profit',
        data: [30, 25, 45, 30, 55, 55],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      min: 0,
      max: 60,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '$' + val + ' thousands'
        },
      },
    },
    colors: [lightColor],
    markers: {
      colors: [lightColor],
      strokeColors: [baseColor],
      strokeWidth: 3,
    },
  }
}

export {Device1Widget}
