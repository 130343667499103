/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import {DeviceData} from '../../../../app/modules/apps/device-data/_models'
import _ from 'lodash'
import {getDeviceData} from '../../../../app/modules/apps/device-data/_requests'
import moment from 'moment'
import {Tooltip} from 'chart.js'

type Props = {
  className: string
  title: string
  description: string
  type: string
  device_id: number
  chartType: number
  refreshEnabled?: boolean
  chartData?: any
}

const ChartsWidgetCrainMovement: React.FC<Props> = ({
  className,
  title,
  description,
  type,
  chartType = 1,
  device_id = 3,
  refreshEnabled = true,
  chartData,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const [chart1, setChart] = useState<ApexCharts | null>(null)

  useEffect(() => {
    console.log('useEffect')
    let interval: NodeJS.Timeout
    if (!chartRef.current) {
      return
    }
    const newChart = new ApexCharts(
      chartRef.current,
      getChartOptions(200, [], [], chartType === 2 ? 'minute' : 'count',chartType)
    )
    newChart.render().then(() => {
      console.log('rendered')
    })

    // setChart(newChart);

    fetchDataAndUpdateChart(newChart).then(() => {
      console.log('fetched')
    })
    if (refreshEnabled) {
      // interval = setInterval(() => {
      //   fetchDataAndUpdateChart();
      // }, 15 * 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
      if (newChart) {
        newChart.destroy()
      }
    }
  }, [mode, type, refreshEnabled, chartType, chartData])

  // useEffect(() => {
  //   let interval: NodeJS.Timeout;

  //   fetchDataAndUpdateChart();

  //   if (refreshEnabled) {
  //

  //     interval = setInterval(() => {
  //       fetchDataAndUpdateChart();
  //     }, 15 * 1000);
  //   }

  //   return () => {
  //     if (interval) {
  //       clearInterval(interval);
  //     }
  //   };
  // }, [chartRef, mode, type, chartType]);

  const refreshChart = (data: DeviceData[], chartType: number, chart: any) => {
    if (!chartRef) {
      console.log('no chart')
      return
    }
    const dayValues = _.map(data, (obj) => obj.day)
    let seriesData
    let legtitle

    if (chartType === 1) {
      let labels
      if (type === 'ht') {
        seriesData = getSeries(data, ['HT Up Count', 'HT Down Count', 'Speed Count'])
      } else if (type === 'ct') {
        seriesData = getSeries(data, ['CT Right Count', 'CT Left Count', 'Speed Count'])
      } else {
        seriesData = getSeries(data, ['LT Forward Count', 'LT Reverse Count', 'Speed Count'])
      }

      legtitle = ' Counts'
    } else if (chartType === 2) {

      if (type === 'ht') {
        seriesData = [
          {
            name: 'HT Up time',
            data: _.map(data, (d) => convertData(Number(d.fwd))),
          },
          {
            name: 'HT Down time',
            data: _.map(data, (d) => convertData(Number(d.rev))),
          },
          {
            name: 'Double Speed time',
            data: _.map(data, (d) => convertData(Number(d.fwd_ds)) + convertData(Number(d.rev_ds))),
          },
        ]
      } else if (type === 'ct') {
        seriesData = [
          {
            name: 'CT Right time',
            data: _.map(data, (d) => convertData(Number(d.fwd))),
          },
          {
            name: 'CT Left time',
            data: _.map(data, (d) => convertData(Number(d.rev))),
          },
          {
            name: 'Double Speed time',
            data: _.map(data, (d) => convertData(Number(d.fwd_ds)) + convertData(Number(d.rev_ds))),
          },
        ]
      } else {
        seriesData = [
          {
            name: 'LT Forward time',
            data: _.map(data, (d) => convertData(Number(d.fwd))),
          },
          {
            name: 'LT Reverse time',
            data: _.map(data, (d) => convertData(Number(d.rev))),
          },
          {
            name: 'Double Speed time',
            data: _.map(data, (d) => convertData(Number(d.fwd_ds)) + convertData(Number(d.rev_ds))),
          },
        ]
      }

      legtitle = ' Time'
    } else if (chartType === 3) {
      if (type === 'ht') {
        seriesData = [
          {
            name: 'HT Brake Count',
            data: _.map(data, (d) => (d.break_count)),
          },
        ]
      } else if (type === 'ct') {
        seriesData = [
          {
            name: 'CT Brake Count',
            data: _.map(data, (d) => (d.break_count)),
          },
        ]
      } else {
        seriesData = [
          {
            name: 'LT Brake Count',
            data: _.map(data, (d) => (d.break_count)),
          },
        ]
      }

      legtitle = ' Count'
    } else if (chartType === 4) {
      const nedaata =_.map(data, (d) => ((d.alarm_count)))
      if (type === 'ht') {
        seriesData = [
          {
            name: 'HT Alarm Count',
            data: _.map(data, (d) => d.alarm_count),
          },
        ]
      } else if (type === 'ct') {
        seriesData = [
          {
            name: 'CT Alarm Count',
            data: _.map(data, (d) => d.alarm_count),
          },
        ]
      } else {
        seriesData = [
          {
            name: 'LT Alarm Count',
            data: _.map(data, (d) => d.alarm_count),
          },
        ]
      }

      legtitle = ' Count'
    }

    if (chart) {
      chart.updateOptions({
        xaxis: {
          categories: dayValues,
        },
        title: {
          text: `${title} - ${legtitle}`,
        },
        tooltip: {
          x: {
            enabled: true,
          },
        },
      })

      chart.updateSeries(seriesData)
    }
  }
  const fetchDataAndUpdateChart = async (chart: any) => {
    try {
      // const response = await axios.get(`${process.env.REACT_APP_DEVICE_DATA_API_URL}?device_id=3&data_type=${type}`);
      const response = chartData
      // = await getDeviceData(`${type}_data`,moment().subtract(7, 'days').format('YYYY-MM-DD'),moment().format('YYYY-MM-DD'))

      if (!chart) {
        console.log('no chart')
      }
      refreshChart(response, chartType, chart)
    } catch (error) {
      // handle any errors that occur
    }
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header'>
        {/* begin::Title */}
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
        </h3>
        {/* end::Title */}

        {/* begin::Toolbar */}
        <div className='card-toolbar'>
          {/* begin::Menu */}

          {/* end::Menu */}
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '150px'}} />
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ChartsWidgetCrainMovement}

function getSeries(data: DeviceData[], labels: string[]) {
  return [
    {
      name: labels[0],
      data: _.map(data, (d) => Number(d.fwd_count)),
    },
    {
      name: labels[1],
      data: _.map(data, (d) => Number(d.rev_count)),
    },
    {
      name: labels[2],
      data: _.map(data, (d) => Number(d.fwd_ds_count) + Number(d.rev_ds_count)),
    },
  ]
}

function convertData(data: number) {
  return Math.round(data / (1000 * 60))
}

function getChartOptions(height: number, xvalues: any, data: any, legtitle = 'count',chartType:number): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-primary')
  const secondaryColor = getCSSVariableValue('--bs-yellow')
  const thirdColor = getCSSVariableValue('--bs-success')
const seriese_color = chartType===4?['#b00030']: [baseColor, secondaryColor, thirdColor]
  return {
    series: data,
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '70%',
        borderRadius: 0,
        dataLabels: {
          position: 'top',
        },
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: [labelColor],
      },

      offsetY: -20,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: xvalues,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return +val + ' ' + legtitle
        },
      },
    },
    colors: seriese_color,
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
