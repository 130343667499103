import React, {FC, useEffect, useState} from 'react'
import Pagination from './Pagination'
// @ts-ignore
import * as XLSX from 'xlsx/xlsx.mjs'
import {Card, Table} from 'react-bootstrap'
import moment from 'moment'
import {paginate, removeIdAndAddSrNo} from '../../../util/paginate'
import {supabase} from '../../../supabaseClient'
import {KTIcon} from '../../../../_metronic/helpers'

const GenerateReport: FC = () => {
  const [ltData, setltData] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 20
  const [selectedDevice, setSelectedDevice] = useState('1')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    setltData(null)
    const fetchData = async () => {
      let {data: flow_data, error} = await supabase
        .from('flow_data')
        .select('*')
        .eq('device_id', 1)
        .lte(
          'created_at',
          moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        )

        .order('created_at', {ascending: false})
      // console.log(data)
      if (error) console.log('error', error)
      else {
        // @ts-ignore
        setltData(flow_data)
      }
    }
    fetchData()
    // const intervalId = setInterval(() => {
    //     fetch('http://flowlogger.link/api/device_data/1',{ mode: 'cors' })
    //     .then((res) => res.json())
    //     .then((ltData) => {
    //         setltData(ltData.data)
    //     })
    // }, 60000);
  }, [])
  if (!ltData) return <p>No device data</p>

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const getData = async () => {
    if (moment(fromDate).isAfter(toDate)) {
      setError('From date must be less than to date')
      alert('From date must be less than to date')
      return
    }
    let {data: flow_data, error} = await supabase
      .from('flow_data')
      .select('*')
      .eq('device_id', selectedDevice)
      .gte('created_at', moment(fromDate).format('YYYY-MM-DD HH:mm:ss'))
      .lte('created_at', moment(toDate).format('YYYY-MM-DD HH:mm:ss'))
      .order('created_at', {ascending: false})
    // console.log(data)
    if (error) console.log('error', error)
    else {
      // @ts-ignore
      setltData(flow_data)
    }
  }

  const deviceName = (id: number) => {
    if (id === 1) {
      return 'Plant 01 Ground Water'
    } else if (id === 2) {
      return 'Plant 02 Ground Water'
    } else if (id === 3) {
      return 'EcoZone Ground Water'
    }
  }

  const paginateltData = paginate(ltData, currentPage, pageSize)

  // @ts-ignore
  let _tmp_webstorm_ = ltData
  const {length, id: id1} = ltData
  // @ts-ignore
  // @ts-ignore
  return (
    <div>
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Last 30 days data</span>
          </h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-light-primary me-3'
              onClick={() => exportExcel(removeIdAndAddSrNo(ltData))}
            >
              <KTIcon iconName='exit-up' className='fs-2' />
              Export
            </button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          <div className='d-flex flex-wrap my-2'>
            <div className='me-4'>
              <div className='form-floating'>
                <select
                  id='device_cahnge'
                  onChange={(e) => setSelectedDevice(e.target.value)}
                  data-control='select2'
                  data-hide-search='true'
                  className='form-select form-select-sm form-select-white w-155px'
                >
                  <option value='1'>Plant 01 Ground Water</option>
                  <option value='2'>Plant 02 Ground Water</option>
                  <option value='3'>EcoZone Ground Water</option>
                </select>
                <label htmlFor='device_cahnge'>Select Crane</label>
              </div>
            </div>
            <div className='me-4'>
              <div className='form-floating' >
                <input
                  type='date'
                  onChange={(e) => setFromDate(e.target.value)}
                  value={fromDate}
                  id='from_date'
                  autoComplete='off'
                  className='form-select form-select-sm form-select-white w-155px'
                  max={moment().format('YYYY-MM-DD')}
                />
                <label htmlFor='from_date'>Select start Date</label>
              </div>
            </div>
            <div className='me-4'>
              <div className='form-floating'>
                <input
                  type='date'
                  onChange={(e) => setToDate(e.target.value)}
                  value={toDate}
                  id='to_date'
                  autoComplete='off'
                  className='form-select form-select-sm form-select-white w-155px'
                  max={moment().format('YYYY-MM-DD')}
                />
                <label htmlFor='from_date'>Select End Date</label>
              </div>
            </div>
            <div className='me-4'>
              <button className='btn btn-primary btn-sm' onClick={() => getData()}>
                {' '}
                Show data
              </button>
            </div>
          </div>

          {error && moment(fromDate) > moment(toDate) ? (
            <p className='text-red-500   text-sm font-medium'>
              start date should be less than end date
            </p>
          ) : (
            ''
          )}
          <Table className='table align-middle gs-0 gy-4' responsive bordered hover>
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-125px rounded-start'> Sr. No</th>

                <th className='min-w-125px'> Date</th>
                <th className='min-w-125px'>Flow Rate</th>
                <th className='min-w-125px'>Totalizer</th>
              </tr>
            </thead>
            <tbody>
              {paginateltData.map((ltData, index) => (
                <tr key={ltData.id}>
                  <td>{index + 1}</td>

                  <td>{moment(ltData.created_at).format('DD-MM-YYYY  h:mm:ss a')}</td>
                  <td>{ltData.flow_rate}</td>
                  <td>{ltData.totalizer}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            items={length}
            currentPage={currentPage}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
        {/* end::Body */}
      </div>

      <Card>
        <Card.Header> {deviceName(id1)}</Card.Header>
        <Card.Body></Card.Body>
      </Card>
    </div>
  )
}

function exportExcel(data: any) {
  const updData = [
    {
      'Device ID': 'test',
      location: 'esat',
      'start time': 'sgsdgds',
      'end time': 'sgsdgds',
    },
  ]
  const newData = [updData, ...data]
  const ws = XLSX.utils.json_to_sheet(data)
  const ws1 = XLSX.utils.json_to_sheet(updData)
  // const mergedSheet = { ...ws, ...ws1 };

  const wb = XLSX.utils.book_new()
  // const wb1 = XLSX.utils.book_new()

  // XLSX.utils.book_append_sheet(wb1, ws1, 'Flowmeter_data')

  XLSX.utils.book_append_sheet(wb, ws, 'Flowmeter_data')
  XLSX.writeFile(wb, 'sheetjs.xlsx')
}

//   function updateData() {
//     fetch('/api/device_data/1')
//     .then((res) => res.json())
//     .then((ltData) => {
//         ltData(ltData.data)
//     })
//   }

export default GenerateReport
