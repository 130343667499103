import React, {FC, useEffect, useState} from 'react'
import Pagination from './Pagination'
// @ts-ignore
import * as XLSX from 'xlsx/xlsx.mjs'
import {Card, Table} from 'react-bootstrap'
import moment from 'moment'
import {paginate, paginate1, removeIdAndAddSrNo} from '../../../util/paginate'
import {supabase} from '../../../supabaseClient'
import {KTIcon} from '../../../../_metronic/helpers'
import ExcelJS from 'exceljs'
import _ from 'lodash'
import {useAuth} from '../../auth'
import {USER_ROLES} from '../../../util/roles'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

interface DeviceDashboardParams {
  device_id: string
  critical_param: any[] // Define the actual type for critical_param
  warning_param: any[] // Define the actual type for warning_param
  normal_param: any[] // Define the actual type for normal_param
}

const DeviceDashboard: FC = () => {
  const [devices, setDevices] = useState(null)
  const [customers, setCustomers] = useState([])
  const [departments, setDepartments] = useState([])
  const [selectedDeviceStatus, setSelectedDeviceStatus] = useState<any>(null)

  const [dataFetched, setDataFetched] = useState(false) // Track data fetching completion
  const [deviceHealthParams, setDeviceDashboardParams] = useState<DeviceDashboardParams[] | null>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [paginatedData, setPaginatedData] = useState([])
  const {currentUser, logout} = useAuth()
  const [selectedDepartment, setselectedDepartment] = useState<any>(null)
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null)
  const [is7Days, setIs7Days] = useState(true)
  const [isActive, setIsActive] = useState(true)


  const pageSize = 10
  // @ts-ignore
  useEffect(() => {




    const fetchCustomers = async () => {
      try {

        let query = supabase
          .from('organization')
          .select(`id,name`);

        if (currentUser?.role === USER_ROLES.CUSTOMER_ADMIN || currentUser?.role === 'customer-user' ) {
          query = query.eq('id', currentUser?.org_id);
        }
        let { data: customers, error } = await query;
        console.log(customers)

        if (error) console.log('error', error)
        else {
          // @ts-ignore
          let result = []


          // result = await getResult(customers)

          // @ts-ignore
          // setDevices('sdsdsdsdsd',deviceHealthParams)

          // @ts-ignore
          setCustomers(customers)
          // @ts-ignore
          // setDeviceDashboardParams(result)

          setDataFetched(true)
        }
      } catch (e) {
        console.log('error', e)
      }
    }



    fetchCustomers()
    // fetchData()
  }, [])



  function updateKeyNames(dataArray: any[]) {
    return dataArray.map((item) => {
      return Object.keys(item).reduce((acc, key) => {
        // Remove "_sum" from the key
        const newKey = key.replace('_sum', '')
        // Assign the value to the new key
        // @ts-ignore
        acc[newKey] = item[key]
        return acc
      }, {})
    })
  }
  function isWithinPercentageRange(
    a: number,

    min: number,
    max: number
  ) {
    // const lowerThreshold = b * (minPercentage / 100)
    // const upperThreshold = b * (maxPercentage / 100)

    if (a >= max) {
      return 'critical'
    } else if (a >= min && a <= max) {
      return 'warning'
    } else {
      return 'normal'
    }
  }
  async function getResult(devices_param: any[]) {
    const result = []


    for (const device of devices_param) {
      if( _.isUndefined(_.get(device,'device_name'))){
        continue
      }
      console.log(devices_param)
      // let {data: devices, error} = await supabase
      //   .from('get_device_health_param_sums_w_days')
      //   .select(`ht_cycle,
      //       ct_cycle,
      //       lt_cycle,
      //       ht_brakes,
      //       lt_brakes,
      //       ct_brakes,
      //       ht_trips,
      //       ct_trips,
      //       lt_trips`)
      //   .eq('device_id', device.device_id)
      let { data: totals } = await supabase
        .rpc('get_device_health_param_sums_w_days', { dev_id:device.id,days: is7Days?7:30 })
      console.log('totals>>>>>>>>>>>>.', totals)
      // @ts-ignore
      let devices=updateKeyNames(totals)
      console.log('devices--------------', _.get(devices, '[0]'))
      console.log('devices--------------', checkActiveStatus(_.get(devices, '[0].max_created_at')))
      const params = []
      const critical_param = []
      const warning_param = []

      for (const key of Object.keys(devices ? devices[0] : [])) {

        const value =_.get(devices, '[0].'+ key)
        const warning_value = _.get(device, key+'_warning')
        const critical_value = _.get(device, key+'_critical')
        if(key === 'max_created_at'){
          console.log('value', {key: 'device_status', value: checkActiveStatus(value)})
          params.push({key, value: checkActiveStatus(value),status: 'normal'})
          // params.push(checkActiveStatus(value) === 'Live' ? {key: 'device_status', value: 'Live'} : {key: 'device_status', value: 'Disconnected'})
        }

        if (isWithinPercentageRange(value, warning_value, critical_value) === 'critical') {
          params.push({key, value, status: 'critical'})
        } else if (isWithinPercentageRange(value, warning_value, critical_value) === 'warning') {
          params.push({key, value, status: 'warning'})
        } else {
          params.push({key, value, status: 'normal'})
        }
      }

      console.log('params------------', devices)
      const tdata = {
        device_id:  _.get(device,'device_name'),
        device_status: checkActiveStatus(_.get(devices, '[0].max_created_at')),
        ...Object.assign(
          {},
          ...params.map((param) => ({
            [param.key]: {
              value: param.value,
              status: param.status,
            },
          }))
        ),
      }


      switch (selectedDeviceStatus) {
        case 'online':
          if(tdata.device_status === 'Live')
          result.push(tdata)
          break
        case 'offline':
          if(tdata.device_status === 'Offline')
          result.push(tdata)
          break
        default:
          result.push(tdata)
          break
      }

      if(isActive && tdata.device_status === 'Live')
      result.push(tdata)
      else if(!isActive && tdata.device_status === 'offline')
        result.push(tdata)

    }

    return result
  }

  function checkActiveStatus(timestamp:any) {
    const givenTimestamp: any = new Date(timestamp);
    const currentTime:any = new Date();
    const differenceInMilliseconds :number = currentTime - givenTimestamp;
    const differenceInMinutes = differenceInMilliseconds / (1000 * 60);

    return differenceInMinutes <= 5 ? "Live" : "Offline";
  }

  const fetchData = async () => {
    try {

      // if (currentUser?.role === 'customer-admin' || currentUser?.role === 'customer-user') {
      //   result = await supabase
      //     .from('device')
      //     .select('id,device_name')
      //     .eq('org_id', currentUser?.org_id)
      // } else {
      //   result = await supabase.from('device').select('id,device_name')
      // }
      let query = supabase
        .from('device')
        .select(`id,device_name`)
        .eq('org_id', selectedCustomer);

      // if (currentUser?.role === USER_ROLES.CUSTOMER_ADMIN || currentUser?.role === USER_ROLES.CUSTOMER_USER) {
      //   query = query.eq('device.org_id', selectedCustomer);
      // }
      let { data: devices, error } = await query;
      // console.log(data)

      if (error) console.log('error', error)
      else {
        // @ts-ignore
        let result = []
        console.log('devices', devices)

        // @ts-ignore
        result = await getResult(devices)

        // @ts-ignore
        // setDevices('sdsdsdsdsd',deviceHealthParams)

        // @ts-ignore
        setDevices(devices)
        console.log('result', result)
        // @ts-ignore
        setDeviceDashboardParams(result)

        setDataFetched(true)
      }
    } catch (e) {
      console.log('error', e)
    }
  }

  const fetchDepartments = async (org_id:any) => {
    try {

      let query = supabase
        .from('department')
        .select(`id,name`)
        .eq('organization_id', org_id);

      // if (currentUser?.role === USER_ROLES.CUSTOMER_ADMIN ) {
      //   query = query.eq('device.org_id', currentUser?.org_id);
      // }
      let { data: departments, error } = await query;
      console.log(departments)

      if (error) console.log('error', error)
      else {
        // @ts-ignore
        let result = []


        // result = await getResult(customers)

        // @ts-ignore
        // setDevices('sdsdsdsdsd',deviceHealthParams)
        if(departments.length >= 0){
          setselectedDepartment(null)
        }
        // @ts-ignore
        setDepartments(departments)
        // @ts-ignore
        // setDeviceDashboardParams(result)

        setDataFetched(true)
      }
    } catch (e) {
      console.log('error', e)
    }
  }

  // useEffect(() => {
  //   if (dataFetched) {
  //     console.log('deviceHealthParams use effecr called', deviceHealthParams)
  //    // let paginateltData = paginate1(deviceHealthParams, currentPage, pageSize);
  //
  //     // @ts-ignore
  //     // setPaginatedData(deviceHealthParams)
  //     console.log('paginateltData----------------', deviceHealthParams)
  //   }
  // }, [deviceHealthParams, currentPage, dataFetched]);
  // console.log('deviceHealthParams%%%%%%%%%%%%%', deviceHealthParams)
  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  // @ts-ignore

  // @ts-ignore
  // let _tmp_webstorm_ = users
  const length = deviceHealthParams?.length
  // deviceHealthParams.map((user:any, index) => (
  //   console.log('user++++++++++++++++++++++++++++++', user)
  // ));
  // console.log('deviceHealthParams%#############', deviceHealthParams)
  //
  if (!customers) {
    return <p>Loading...</p>
  }
  // @ts-ignore
  // @ts-ignore
  return (
    <div>
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <div className='card-toolbar'></div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* Search component */}

            <Table className='table gy-6 gs-6'>
              <thead>
                <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                  <th scope='col' className='min-w-125px rounded-start'>
                    Customer
                  </th>
                  <th scope='col' className='min-w-125px text-center'>
                    Department
                  </th>

                  <th scope='col' className='min-w-125px text-center'>
                    Status
                  </th>
                  {/*<th scope='col' className='min-w-125px rounded-start'>*/}
                  {/*  {' '}*/}
                  {/*  Device Name*/}
                  {/*</th>*/}

                  <th scope='col' className='min-w-125px rounded-start'>
                    Duration
                  </th>
                  <th scope='col' className='min-w-125px rounded-start'></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <select
                      name='customer'
                      data-control='select2'
                      data-hide-search='true'
                      className='form-select form-select-solid form-select-lg'
                      value={selectedCustomer}
                      onChange={(e) => {
                        console.log('selectedCustomer', e.target.value)
                        setSelectedCustomer(e.target.value)
                        window.localStorage.setItem('selectedCustomer', e.target.value)
                        fetchDepartments(e.target.value)
                        // fetchActionData(e.target.value, motionValue)
                        // fetchData(e.target.value, motionValue)
                      }}
                    >
                      <option key='0' value='Select Crane'>
                        Select Customer
                      </option>
                      {customers.map((customer: any) => (
                        <option key={customer.id} value={customer.id}>
                          {customer.name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      name='department'
                      data-control='select2'
                      data-hide-search='true'
                      className='form-select form-select-solid form-select-lg'
                      value={selectedDepartment}
                      onChange={(e) => {
                        setselectedDepartment(e.target.value)
                        window.localStorage.setItem('selectedDepartment', e.target.value)
                        // fetchActionData(e.target.value, motionValue)
                        // fetchData(e.target.value, motionValue)
                      }}
                    >
                      <option key='0' value='Select Crane'>
                        Select Department
                      </option>
                      {departments.map((dept: any) => (
                        <option key={dept.id} value={dept.id}>
                          {dept.name}
                        </option>
                      ))}
                    </select>
                  </td>

                  <td>
                    <select
                      name='device_status'
                      data-control='select2'
                      data-hide-search='true'
                      className='form-select form-select-solid form-select-lg'
                      value={selectedDeviceStatus}
                      onChange={(e) => {
                        setSelectedDeviceStatus(e.target.value)
                        window.localStorage.setItem('setSelectedDeviceStatus', e.target.value)

                      }}
                    >
                      <option key='0' value='Select Status'>
                        Select Status
                      </option>

                      <option key='1' value='online'>
                        Online
                      </option>
                      <option key='2' value='offline'>
                        Offline
                      </option>
                      <option key='3' value='both'>
                        Both status
                      </option>
                    </select>

                  </td>
                  {/*<td></td>*/}
                  <td scope='col' className='min-w-125px rounded-start'>
                    <BootstrapSwitchButton
                      onlabel='Last 7 days'
                      offlabel='Last 30 days'
                      size={'lg'}
                      checked={is7Days}
                      onChange={(checked: boolean) => {
                        setIs7Days(checked)
                        // fetchActionData(selectedDevice,motionValue,checked)
                        // fetchData(selectedDevice,motionValue,checked)
                      }}
                      style='w-100 mx-2'
                    />
                  </td>
                  <td scope='col' className='min-w-125px rounded-start'>
                    <button
                      className='btn btn-primary'
                      onClick={() => {
                        // fetchActionData(selectedDevice,motionValue)
                        fetchData()
                      }}
                    >
                      Search
                    </button>
                  </td>
                </tr>
              </tbody>
            </Table>

            <Table className='table table-bordered gy-7 gs-7'>
              <thead>
                <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                  <th scope='col' className='min-w-125px rounded-start'>
                    {' '}
                    Device Name
                  </th>
                  <th scope='col' className='min-w-125px rounded-start'>
                    {' '}
                  </th>
                  <th scope='col' colSpan={3} className='min-w-125px text-center'>
                    {' '}
                    HT
                  </th>
                  <th scope='col' colSpan={3} className='min-w-125px text-center'>
                    CT
                  </th>
                  <th scope='col' colSpan={3} className='min-w-125px text-center'>
                    LT
                  </th>
                </tr>
                <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                  <th scope='col' className='min-w-25px rounded-start'>
                    {' '}
                  </th>
                  <th scope='col' className='min-w-25px rounded-start'>
                    Device Status
                  </th>

                  <th scope='col' className='min-w-125px'>
                    {' '}
                    Cycles
                  </th>
                  <th scope='col' className='min-w-125px'>
                    Brakes
                  </th>
                  <th scope='col' className='min-w-125px'>
                    Trips
                  </th>
                  <th scope='col' className='min-w-125px'>
                    {' '}
                    Cycles
                  </th>
                  <th scope='col' className='min-w-125px'>
                    Brakes
                  </th>
                  <th scope='col' className='min-w-125px'>
                    Trips
                  </th>
                  <th scope='col' className='min-w-125px'>
                    {' '}
                    Cycles
                  </th>
                  <th scope='col' className='min-w-125px'>
                    Brakes
                  </th>
                  <th scope='col' className='min-w-125px'>
                    Trips
                  </th>
                </tr>
              </thead>
              {dataFetched && deviceHealthParams ? (
                <tbody>
                  {deviceHealthParams.map((user: any, index: number) => (
                    <tr>
                      <td>{user.device_id}</td>
                      <td
                        className={
                          user.device_status === 'Live'
                            ? 'bg-primary text-light'
                            : 'bg-danger text-light'
                        }
                      >
                        {user.device_status}
                      </td>
                      <td>
                        {' '}
                        <span
                          className={`badge ${
                            user.ht_cycle.status === 'critical'
                              ? 'badge-danger'
                              : user.ht_cycle.status === 'warning'
                              ? 'badge-warning'
                              : 'badge-success'
                          }`}
                        >
                          {user.ht_cycle.value}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`badge ${
                            user.ht_brakes.status === 'critical'
                              ? 'badge-danger'
                              : user.ht_brakes.status === 'warning'
                              ? 'badge-warning'
                              : 'badge-success'
                          }`}
                        >
                          {user.ht_brakes.value}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`badge ${
                            user.ht_trips.status === 'critical'
                              ? 'badge-danger'
                              : user.ht_trips.status === 'warning'
                              ? 'badge-warning'
                              : 'badge-success'
                          }`}
                        >
                          {user.ht_trips.value}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`badge ${
                            user.ct_cycle.status === 'critical'
                              ? 'badge-danger'
                              : user.ct_cycle.status === 'warning'
                              ? 'badge-warning'
                              : 'badge-success'
                          }`}
                        >
                          {user.ct_cycle.value}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`badge ${
                            user.ct_brakes.status === 'critical'
                              ? 'badge-danger'
                              : user.ct_brakes.status === 'warning'
                              ? 'badge-warning'
                              : 'badge-success'
                          }`}
                        >
                          {user.ct_brakes.value}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`badge ${
                            user.ct_trips.status === 'critical'
                              ? 'badge-danger'
                              : user.ct_trips.status === 'warning'
                              ? 'badge-warning'
                              : 'badge-success'
                          }`}
                        >
                          {user.ct_trips.value}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`badge ${
                            user.lt_cycle.status === 'critical'
                              ? 'badge-danger'
                              : user.lt_cycle.status === 'warning'
                              ? 'badge-warning'
                              : 'badge-success'
                          }`}
                        >
                          {user.lt_cycle.value}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`badge ${
                            user.lt_brakes.status === 'critical'
                              ? 'badge-danger'
                              : user.lt_brakes.status === 'warning'
                              ? 'badge-warning'
                              : 'badge-success'
                          }`}
                        >
                          {user.lt_brakes.value}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`badge ${
                            user.lt_trips.status === 'critical'
                              ? 'badge-danger'
                              : user.lt_trips.status === 'warning'
                              ? 'badge-warning'
                              : 'badge-success'
                          }`}
                        >
                          {user.lt_trips.value}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={10}>Loading...</td>
                  </tr>
                </tbody>
              )}
              <tfoot>
                <tr>
                  <td colSpan={7}></td>
                  <td colSpan={3}>
                    <span className='badge  badge-danger'> Critical values</span> &nbsp;
                    <span className='badge  badge-warning'> Warning values</span>&nbsp;
                    <span className='badge  badge-success'> Normal values</span>
                  </td>
                </tr>
              </tfoot>
            </Table>
            <Pagination
              items={length}
              currentPage={currentPage}
              pageSize={pageSize}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
        {/* end::Body */}
      </div>
    </div>
  )
}

export default DeviceDashboard
