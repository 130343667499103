import {PageTitle} from "../../../_metronic/layout/core";
import {useIntl} from "react-intl";
import  TableData from '../../modules/widgets/components/TableData'
import React from 'react'
import GenerateReport from '../../modules/widgets/components/GenerateReport'


const GenerateReportPage = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]} >
                Generate Report
            </PageTitle>
            <div>
              <div className='g-5 gx-xxl-8'>
                      <GenerateReport/>
                </div>
            </div>
        </>

    )
}

const GenerateReportWrapper = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
            <GenerateReportPage/>
        </>
    )
}

export {GenerateReportWrapper}
