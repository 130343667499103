const QUERIES = {
  USERS_LIST: 'users-list',
}

const DEV_QUERIES = {
  DEVICE_LIST: 'device-list',
}

const ORG_QUERIES = {
  ORG_LIST: 'health-param-list',
}
const DEPT_QUERIES = {
  DEPT_LIST: 'dept-list',
}
export {QUERIES, DEV_QUERIES,ORG_QUERIES,DEPT_QUERIES}
