import {PageTitle} from "../../../_metronic/layout/core";
import {useIntl} from "react-intl";
import React from 'react'
import DeviceDashboard from '../../modules/widgets/components/DeviceDashboard'


const DeviceDashboardPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]} >
        Device  Monitoring
      </PageTitle>
      <div>
        <div className='g-5 gx-xxl-8'>
          <DeviceDashboard/>
        </div>
      </div>
    </>

  )
}

const DeviceDashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DeviceDashboardPage/>
    </>
  )
}

export {DeviceDashboardWrapper}
