import _ from 'lodash';
import moment from 'moment/moment'

export const paginate = (items: any[], pageNumber: number, pageSize: number) => {
  const startIndex = (pageNumber - 1) * pageSize;
  return _(items).slice(startIndex).take(pageSize).value();
};

export const paginate1 = (items: any[], pageNumber: number, pageSize: number) => {
  const startIndex = (pageNumber - 1) * pageSize;

  console.log('============================',items)
  return items;

};
export function removeIdAndAddSrNo(data: any[]) {
  const newData = data.map((item, index) => {
    const { id, flow_rate, totalizer, created_at, ...rest } = item; // Destructure the id, flow_rate, totalizer, created_at properties and the rest of the properties
    return {
      sr_no: index + 1,
      'Flow Rate': flow_rate,
      Totalizer: totalizer,
      'Time and Date': moment(created_at).format('YYYY-MM-DD HH:mm:ss')
    };
  });
  return newData;
}
